import gql from 'graphql-tag';
import {Driver} from './driverQuery';
import {AssignedOrder} from './deliveryQuery';

export type RIT = {
  id: string;
  driver: Driver;
  assignedOrder: Array<AssignedOrder>;
  completed: boolean;
  started: boolean;
};

export type RITOrderByInput =
  | 'id_ASC'
  | 'id_DESC'
  | 'completed_ASC'
  | 'completed_DESC'
  | 'started_ASC'
  | 'started_DESC'
  | 'active_ASC'
  | 'active_DESC'
  | 'createdAt_ASC'
  | 'createdAt_DESC';

export type DriverRITListResult = {rITs: Array<RIT>};

export type DriverRITListParams = {
  where: ObjectKey;
  orderBy?: RITOrderByInput;
};

const driverRITFragment = gql`
  fragment DriverRITFragment on RIT {
    id
    driver {
      driverName
      driverNIK
      vehicle {
        loadType
        vehicleType {
          typeName
          capacity
        }
      }
    }
    assignedOrder {
      id
      deliveryItems {
        id
        transactionItem {
          products {
            productData {
              title
              uom
            }
            unitQuantity
          }
        }
        deliveryType
        quantityDelivery
      }
      status
      doNumber
    }
    completed
    started
  }
`;

export const GET_DRIVER_RITS = gql`
  query getDriverRITs($where: RITWhereInput, $orderBy: RITOrderByInput) {
    rITs(where: $where, orderBy: $orderBy) {
      ...DriverRITFragment
    }
  }
  ${driverRITFragment}
`;
