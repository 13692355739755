import gql from 'graphql-tag';
import {canceledOrders, coupon, user} from '../relationalFragments';
import {AssignedOrder} from './deliveryQuery';
import {Driver} from './driverQuery';

export type OrderStatus =
  | 'WAITING'
  | 'ACCEPTED'
  | 'IN_PROCESS'
  | 'IN_DELIVERY'
  | 'PENDING'
  | 'COMPLETED'
  | 'CANCELED_BY_ADMIN'
  | 'CANCELED_BY_DRIVER'
  | 'CANCELED_BY_CUSTOMER';

export type PaymentType = 'TRANSFER' | 'CASH';

export type PaymentStatus = 'PAID' | 'UNPAID';

type Product = {
  id: string;
  nickname: string;
  title: string;
  description: string;
  stock: number;
  photo: string;
  dimension: string;
  uom: string;
  sku: string;
  minimumStock: number;
  active: boolean;
};

export type TransactionProduct = {
  productData: Product;
  unitQuantity: number;
};

export type TransactionItem = {
  id: string;
  products: Array<TransactionProduct>;
  additionalProducts: Array<{name: string; quantity: number}>;
  quantity: number;
  price: number;
  priceCut: number;
};

export type DeliveryItem = {
  id: string;
  deliveryType: 'BOX' | 'GALON';
  transactionItem: TransactionItem;
  quantityDelivery: number;
};

export type AssignedDeliveryDriver = {
  driver: Driver;
  assignedOrder: AssignedOrder;
};

export type CouponType = 'CASHBACK' | 'POTONGAN';

export type Coupon = {
  id: string;
  title: string;
  description: string;
  couponImage: string;
  couponType: CouponType;
  couponRewardValue: number;
  couponPrice: number;
  couponLimit: number;
  couponValidDays: number;
  startDate: string; // NOTE: date ISO string
  endDate: string; // NOTE: date ISO string
  createdAt: string; // NOTE: date ISO string
  active: boolean;
};

export type GallonType = 'AQUA' | 'VIT';

export type CanceledOrder = {
  cancelReason: string;
  deliveryItems: Array<{
    quantityDelivery: number;
    transactionItem: TransactionItem;
  }>;
};

export type Order = {
  id: string;
  status: OrderStatus;
  invoiceNumber: string;
  poNumber: string;
  user: {
    id: string;
    szID: string;
    name: string;
    storeName: string;
    depot: {
      id: string;
      title: string;
    };
    storeAddress: string;
    storeTelephone: string;
  };
  bottleGuarantee: Array<{
    qty: number;
    gallonType: GallonType;
    pricePerBottle: number;
  }>;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  createdAt: string;
  deliveredDate?: string;
  cancelReason?: string;
  canceledDate?: string;
  transactionItems: Array<TransactionItem>;
  assignedDeliveryDrivers: Array<AssignedDeliveryDriver>;
  top: boolean;
  closingId?: string;
  creditPointUse: number;
  coupon?: Coupon;
  canceledOrders: Array<CanceledOrder>;
};

export type OrderListResult = {orders: Array<Order>; count: number};
export type OrderListParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

export type TotalRemainingOrderResult = {
  totalRemainingOrder: {
    totalBox: number;
    totalGalon: number;
  };
};

export type TotalOrderToAssign = {
  orderCount: number;
};

const ORDER_RETURN_VARIABLES = `
    poNumber
    soNumber
    invoiceNumber
    ${user}
    createdAt
    paymentType
    paymentStatus
    creditPointUse
    ${coupon}
    bottleGuarantee {
      gallonType
      qty
      pricePerBottle
    }
    ${canceledOrders}
    transactionItems {
      id
      price
      priceCut
      description
      products{
        productData{
          title
          uom
        }
        unitQuantity
      }
      additionalProducts {
        name
        quantity
      }
      quantity
    }
    assignedDeliveryDrivers{
      driver{
        driverCode
        driverName
      }
      assignedOrder{
        pendingReason
        status
        doNumber
        deliveryItems{
          deliveryType
          transactionItem{
            products{
              productData{
                title
                uom
              }
              unitQuantity
            }
          }
          quantityDelivery
        }
      }
    }
`;

const TOTAL_REMAINING_ORDER_VARIABLES = `
    totalBox
    totalGalon
`;

export const GET_ORDER_LIST = gql`
  query getOrderList($where: TransactionWhereInput, $skip: Int, $first: Int){
    orders(where: $where,first: $first,skip: $skip){
      ${ORDER_RETURN_VARIABLES}
    }
    count: dataCount(node: ORDER, orderWhere: $where)
  }
`;

export const GET_TOTAL_REMAINING_ORDER = gql`
  query getTotalRemainingOrder{
    totalRemainingOrder{
      ${TOTAL_REMAINING_ORDER_VARIABLES}
    }
    orderCount: getCountOrderToAssign
  }
`;

export const GET_TOTAL_UNPROCESSED_ORDER = gql`
  query getTotalUnprocessedOrder {
    count: dataCount(
      node: ORDER
      orderWhere: {
        AND: [
          {status: IN_PROCESS}
          {
            OR: [
              {poNumber_contains: ""}
              {soNumber_contains: ""}
              {
                user: {
                  OR: [
                    {szID_contains: ""}
                    {storeName_contains: ""}
                    {storeTelephone_contains: ""}
                  ]
                }
              }
            ]
          }
        ]
      }
    )
  }
`;

export const GET_NEW_ORDER_NOTIFICATION = gql`
  subscription getNewOrderNotification {
    orderAcceptedNotification {
      id
    }
  }
`;

export const GET_TOTAL_ORDER_TO_ASSIGN = gql`
  query getTotalOrderToAssign {
    orderCount: getCountOrderToAssign
  }
`;
