import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';

import {Icon} from '../../core-ui';
import {PRIMARY} from '../../constants/colors';

type Props = {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (event: CustomMouseEvent, page: number) => void;
};

export default class TablePaginationActions extends Component<Props> {
  render() {
    const {count, page, rowsPerPage} = this.props;

    return (
      <View style={styles.root}>
        <Icon
          name="chevron_left"
          color={PRIMARY}
          disabled={page === 0}
          onPress={(e) =>
            // The `event` from Icon is of type `GestureResponderEvent`
            // Here, we need type `CustomMouseEvent`
            this.handleBackButtonClick((e as unknown) as CustomMouseEvent)
          }
          style={{marginLeft: 50}}
        />
        <Icon
          name="chevron_right"
          color={PRIMARY}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onPress={(e) =>
            // The `event` from Icon is of type `GestureResponderEvent`
            // Here, we need type `CustomMouseEvent`
            this.handleNextButtonClick((e as unknown) as CustomMouseEvent)
          }
          style={{marginLeft: 20, marginRight: 20}}
        />
      </View>
    );
  }

  handleBackButtonClick = (event: CustomMouseEvent) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event: CustomMouseEvent) => {
    this.props.onChangePage(event, this.props.page + 1);
  };
}

let styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
});
