import React, {Component, CSSProperties} from 'react';
import {
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
  GestureResponderEvent,
  StyleSheet,
} from 'react-native';
import * as MaterialIcon from 'react-icons/md';

import {SVG} from '../assets/svg';
import {convertIconName} from '../helpers';

export type IconSize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

type Props = {
  name: IconKey;
  color?: string;
  hoverColor?: string;
  size?: IconSize;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: (e: GestureResponderEvent) => void;
  isCustomSVG?: boolean;
  disabled?: boolean;
};

type State = {isHovered: boolean};

const ICON_SIZE = {
  xsmall: 17,
  small: 24,
  medium: 30,
  large: 40,
  xlarge: 64,
  xxlarge: 120,
};

export default class Icon extends Component<Props, State> {
  state: State = {isHovered: false};

  _onMouseChange = (isHovered: boolean) => this.setState({isHovered});

  render() {
    let {
      name,
      color,
      hoverColor,
      size,
      style,
      onPress,
      isCustomSVG,
      disabled,
      containerStyle,
      ...otherProps
    } = this.props;
    let {isHovered} = this.state;
    let iconName = convertIconName(name);
    let iconSize = ICON_SIZE[size || 'medium'];
    let iconColor = (hoverColor && isHovered ? hoverColor : color) || '';
    let recomp;
    if (isCustomSVG) {
      recomp = React.createElement(SVG[name as SVGKeys], {
        fill: iconColor,
        size: iconSize,
      });
    } else {
      recomp = React.createElement(MaterialIcon[iconName as MDKey], {
        color: iconColor,
        size: iconSize,
        style: style ? (StyleSheet.flatten(style) as CSSProperties) : {},
      });
    }

    let iconComponent = (
      <View
        style={containerStyle || styles.wrapper}
        onMouseOver={() => this._onMouseChange(true)}
        onMouseLeave={() => this._onMouseChange(false)}
      >
        {recomp}
      </View>
    );

    if (onPress) {
      return (
        <TouchableOpacity disabled={disabled} onPress={onPress} {...otherProps}>
          {iconComponent}
        </TouchableOpacity>
      );
    } else {
      return <View {...otherProps}>{iconComponent}</View>;
    }
  }
}

let styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
});
