import React, {Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {RouteComponentProps} from 'react-router';
import {Mutation, OperationVariables} from 'react-apollo';

import {Button, Countdown, Text, TextField} from '../../core-ui';
import {Form, MessagesBox} from '../../components';
import {LandingBackground} from './';
import {CMS_RESET_PASSWORD, ResetPasswordMutation} from '../../graphql/queries';
import {client} from '../../graphql/client';
import {PRIMARY} from '../../constants/colors';

type State = {
  newPassword: string;
  confirmNewPassword: string;
  errorMessage: string;
};

type Props = RouteComponentProps;

export default class SetNewPasswordScene extends Component<Props, State> {
  state: State = {
    newPassword: '',
    confirmNewPassword: '',
    errorMessage: '',
  };

  render() {
    return (
      <LandingBackground>{this._renderForgotPassword()}</LandingBackground>
    );
  }

  _checkPassword = () => {
    let {newPassword, confirmNewPassword} = this.state;
    if (!newPassword || !confirmNewPassword) {
      this.setState({errorMessage: 'Kata sandi tidak boleh kosong'});
      return false;
    } else if (newPassword !== confirmNewPassword) {
      this.setState({errorMessage: 'Kata sandi tidak cocok'});
      return false;
    } else if (newPassword.length < 6) {
      this.setState({
        errorMessage: 'Kata sandi tidak boleh kurang dari 6 karakter',
      });
      return false;
    }
    return true;
  };

  _goToLoginPage = () => {
    let {history} = this.props;
    client.resetStore();
    history.push('/');
  };

  _renderSuccess = () => {
    return (
      <View>
        <Text size="xlarge" style={styles.header}>
          Kata sandi berhasil diubah
        </Text>
        <TouchableOpacity onPress={this._goToLoginPage}>
          <Text style={styles.underLine} weight="light">
            Ke halaman login (
            {<Countdown timeLeft={10} onTimeEnd={this._goToLoginPage} />})
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  _renderForgotPassword = () => {
    let {newPassword, confirmNewPassword, errorMessage} = this.state;
    return (
      <Mutation<any, OperationVariables> mutation={CMS_RESET_PASSWORD}>
        {(resetPassword, {data, error, loading}) => {
          if (data) {
            return this._renderSuccess();
          }
          return (
            <Form onSubmit={() => this._onSubmit(resetPassword)}>
              <Text size="xlarge" style={styles.header}>
                Halo
              </Text>
              <Text style={styles.subHeader} weight="light">
                Silakan masukkan kata sandi anda yang baru.
              </Text>
              {!!errorMessage && (
                <MessagesBox message={errorMessage} type="error" />
              )}
              {!!error && <MessagesBox message={error.message} type="error" />}
              <TextField
                stretch
                secureTextEntry
                label="Kata Sandi Baru"
                value={newPassword}
                placeholder="Kata Sandi Baru"
                onChangeText={(text: string) =>
                  this.setState({
                    newPassword: text,
                  })
                }
                style={{
                  container: {paddingBottom: 20},
                  textField: {
                    color: PRIMARY,
                  },
                }}
              />
              <TextField
                stretch
                secureTextEntry
                label="Konfirmasi Kata Sandi Baru"
                value={confirmNewPassword}
                placeholder="Konfirmasi Kata Sandi Baru"
                onChangeText={(text: string) =>
                  this.setState({
                    confirmNewPassword: text,
                  })
                }
                style={{
                  container: {paddingBottom: 40},
                  textField: {
                    color: PRIMARY,
                  },
                }}
              />
              <Button
                stretch
                text="Ubah Kata Sandi"
                onPress={() => this._onSubmit(resetPassword)}
                isLoading={loading}
              />
            </Form>
          );
        }}
      </Mutation>
    );
  };

  _onSubmit = (resetPassword: ResetPasswordMutation) => {
    let {newPassword} = this.state;
    let {params}: any = this.props.match;
    if (this._checkPassword()) {
      this.setState({errorMessage: ''});
      resetPassword({
        variables: {
          token: params && params.token,
          newPassword,
        },
      });
    }
  };
}

const styles = StyleSheet.create({
  header: {
    paddingBottom: 20,
  },
  subHeader: {
    paddingBottom: 20,
  },
  underLine: {
    textDecorationLine: 'underline',
  },
});
