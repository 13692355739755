import React, {ReactNode} from 'react';
import {StyleSheet, View, StyleProp, ViewStyle, TextStyle} from 'react-native';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import {Button, Icon, Text} from '.';
import {MODAL, WHITE} from '../constants/colors';
import {DialogProps} from '@material-ui/core/Dialog';

const INNER_PADDING = 45;

type HeaderStyle = {
  title?: StyleProp<TextStyle>;
  description?: StyleProp<TextStyle>;
};

export type OwnProps = {
  isVisible: boolean;
  title: string;
  description?: ReactNode;
  buttonText?: string;
  invisible?: boolean;
  transitionTime?: number;
  transitionType?: 'fade' | 'slide';
  children?: ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  isLoading?: boolean;
  maxHeight?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  headerStyle?: HeaderStyle;
};
export type Props = Pick<DialogProps, Exclude<keyof DialogProps, 'open'>> &
  WithStyles<typeof materialStyles> &
  OwnProps;

function Transition(props: {[key: string]: string}) {
  return <Slide direction="up" {...props} />;
}

export function Modal(props: Props) {
  let {
    children,
    isVisible,
    title,
    description,
    buttonText,
    invisible,
    transitionType,
    transitionTime,
    onClose,
    onSubmit,
    isLoading,
    maxHeight,
    containerStyle,
    contentStyle,
    headerStyle,
    classes,
    ...otherProps
  } = props;
  let transition = {};
  if (transitionType === 'slide') {
    transition = {...transition, TransitionComponent: Transition};
  }
  if (transitionTime) {
    transition = {...transition, transitionDuration: transitionTime};
  }
  return isVisible ? (
    <Dialog
      open={isVisible}
      onClose={typeof window.orientation !== 'undefined' ? () => {} : onClose}
      BackdropProps={{invisible}}
      classes={maxHeight ? {paperScrollPaper: classes.maxHeightModal} : {}}
      {...transition}
      {...otherProps}
    >
      <View
        style={[
          styles.container,
          maxHeight && styles.maxHeight,
          containerStyle,
        ]}
      >
        <Header
          title={title}
          desc={description}
          onClose={onClose}
          classes={{root: classes.removePadding}}
          headerStyle={headerStyle}
        />
        {children && (
          <DialogContent classes={{root: classes.removePadding}}>
            <View style={[maxHeight && styles.maxHeight, contentStyle]}>
              {children}
            </View>
          </DialogContent>
        )}
        {buttonText && onSubmit && (
          <DialogActions classes={{root: classes.removeMargin}}>
            <View style={styles.formButton}>
              <Button
                color="primary"
                text={buttonText}
                onPress={onSubmit}
                isLoading={isLoading}
                loadingColor={WHITE}
              />
            </View>
          </DialogActions>
        )}
      </View>
    </Dialog>
  ) : null;
}

type HeaderProps = {
  desc?: ReactNode;
  classes?: ObjectKey;
  title: string;
  onClose: () => void;
  headerStyle?: HeaderStyle;
};

function Header(props: HeaderProps) {
  let {desc, title, onClose, classes, headerStyle} = props;
  return (
    <View style={styles.bottomSpace}>
      <DialogTitle classes={classes}>
        <View style={styles.titleRow}>
          <Text
            size="large"
            color={MODAL.TITLE}
            style={headerStyle && headerStyle.title}
          >
            {title}
          </Text>
          <Icon size="small" name="close" onPress={onClose} />
        </View>
      </DialogTitle>
      {desc && (
        <Text
          size="small"
          color={MODAL.DESCRIPTION}
          style={[styles.topSpace, headerStyle && headerStyle.description]}
        >
          {desc}
        </Text>
      )}
    </View>
  );
}

const materialStyles = createStyles({
  maxHeightModal: {height: '100%'},
  removePadding: {padding: 0},
  removeMargin: {margin: 0, paddingTop: 20},
});

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    minHeight: 200,
    padding: INNER_PADDING,
    backgroundColor: WHITE,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottomSpace: {paddingBottom: 30},
  topSpace: {paddingTop: 30},
  formButton: {flexDirection: 'row', justifyContent: 'flex-end'},
  maxHeight: {height: '100%'},
});

export default withStyles(materialStyles)(Modal);
