import React, {Fragment, useState} from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {Query} from '../components';
import {BLACK, DARK_GRAY, GRAY8, GREEN, PRIMARY} from '../constants/colors';
import {Modal, Separator, Text} from '../core-ui';
import {
  AssignedOrder,
  CanceledOrder,
  Coupon,
  GET_RETURNED_ITEMS,
  GallonType,
  Order,
  ReturnedItem,
  ReturnedListResult,
  ReturnedListVars,
  TransactionItem,
} from '../graphql/queries';
import {
  convertPascalCase,
  formatNumberDate,
  formatThousandSeparator,
  getPaymentStatusString,
  getPaymentTypeString,
} from '../helpers';

import {COMPANY_INFO} from '../constants/companyInfo';

type Props = {
  isVisible?: boolean;
  order: Order & AssignedOrder;
  returnedItem?: ReturnedItem;
  onClose?: () => void;
};

type PurchaseSummaryProps = {
  sumPriceProduct: number;
  creditPointUse: number;
  totalPrice: number;
  coupon?: Coupon;
};

type OrderTableProps = {
  bottleGuarantee: Array<{
    qty: number;
    gallonType: GallonType;
    pricePerBottle: number;
  }>;
  purchaseList: Array<TransactionItem>;
  invoiceNumber: string;
  style?: StyleProp<ViewStyle>;
  purchaseSummary: PurchaseSummaryProps;
  data?: ReturnedListResult;
  canceledOrders: Array<CanceledOrder>;
} & WithStyles<typeof tableStyles>;

function calculateTransaction(order: Order & AssignedOrder) {
  let {transactionItems, deliveryItems, bottleGuarantee} = order;
  let total = 0;
  const deliveryTransactionItems = deliveryItems
    ? deliveryItems.map((item) => item.transactionItem)
    : [];

  for (let {price, priceCut = 0, quantity} of [
    ...(deliveryTransactionItems.length > 0
      ? deliveryTransactionItems
      : transactionItems),
  ]) {
    total += (price - priceCut) * quantity;
  }

  if (bottleGuarantee) {
    for (let {pricePerBottle, qty} of bottleGuarantee) {
      total += pricePerBottle * qty;
    }
  }
  return total;
}

const ReturnedList = (extendedProps: {
  props: Props;
  data?: ReturnedListResult;
}) => {
  let {props, data} = extendedProps;
  let {order} = props;

  let {
    invoiceNumber,
    poNumber,
    user,
    paymentType,
    paymentStatus,
    createdAt: orderDate,
    transactionItems,
    creditPointUse = 0,
    bottleGuarantee,
    coupon,
    canceledOrders,
    deliveryItems,
  } = order;

  let {
    szID: customerId,
    name: customerName,
    storeAddress: customerAddress,
    storeTelephone: customerTelephone,
  } = user;

  let sumPriceProduct = calculateTransaction(order);
  const discount = coupon
    ? coupon.couponType === 'POTONGAN'
      ? coupon.couponRewardValue
      : 0
    : 0;
  let totalPrice = Math.max(0, sumPriceProduct - creditPointUse - discount);
  let purchaseSummary = {
    sumPriceProduct,
    totalPrice,
    creditPointUse,
    coupon,
  };

  const deliveryTransactionItems = deliveryItems
    ? deliveryItems.map((item) => item.transactionItem)
    : [];
  const purchaseLists = [
    ...(deliveryTransactionItems.length > 0
      ? deliveryTransactionItems
      : transactionItems),
  ];

  return (
    <View style={styles.flex}>
      <View style={[styles.row, styles.bottomSpacing]}>
        <Field label="Nama Depo" style={styles.flex}>
          {convertPascalCase(user.depot.title)}
        </Field>
        <Field label="Waktu" style={styles.flex}>
          {formatNumberDate(new Date(orderDate))}
        </Field>
      </View>
      <View style={styles.row}>
        <Field label="Nomor Invoice" style={styles.flex}>
          {invoiceNumber}
        </Field>
        <Field label="Nomor Pesanan" style={styles.flex}>
          {poNumber}
        </Field>
      </View>
      <Separator style={styles.separator} />
      <Text weight="bold" style={styles.bottomSpacing}>
        Pengiriman
      </Text>
      <View style={[styles.row, styles.bottomSpacing]}>
        <Field label="Nama Penerima" style={styles.flex}>
          {customerName}
        </Field>
        <Field label="Kode Pelanggan" style={styles.flex}>
          {customerId}
        </Field>
      </View>
      <View style={styles.row}>
        <Field label="Alamat Tujuan" style={styles.flex}>
          {customerAddress}
        </Field>
        <Field label="Nomor HP Penerima" style={styles.flex}>
          {customerTelephone}
        </Field>
      </View>
      <Separator style={styles.separator} />
      <Text weight="bold" style={styles.bottomSpacing}>
        Pembayaran
      </Text>
      <View style={styles.row}>
        <Field label="Jenis Pembayaran" style={styles.flex}>
          {getPaymentTypeString(paymentType, false)}
        </Field>
        <Field label="Status Pembayaran" style={styles.flex}>
          {getPaymentStatusString(paymentStatus, false)}
        </Field>
      </View>
      <Separator style={styles.separator} />
      <OrderTable
        bottleGuarantee={bottleGuarantee}
        purchaseList={purchaseLists}
        invoiceNumber={invoiceNumber}
        style={styles.bottomSpacing}
        purchaseSummary={purchaseSummary}
        data={data}
        canceledOrders={canceledOrders}
      />
      {/* // TODO: Commented until further information
        {PROMO && (
          <View style={styles.promoBoxx}>
            <Text>
              Kupon potongan harga Rp. {formatThousandSeparator(PROMO.value)}
            </Text>
          </View>
        )} */}
    </View>
  );
};

const OrderDetailModal = (props: Props) => {
  let {isVisible, onClose, returnedItem} = props;

  return (
    <Modal
      title={COMPANY_INFO.name}
      description={COMPANY_INFO.address}
      isVisible={!!isVisible}
      onClose={() => onClose && onClose()}
      maxHeight
      fullWidth
      maxWidth="md"
      headerStyle={{
        title: styles.titleHeader,
        description: styles.descriptionHeader,
      }}
    >
      {!!returnedItem ? (
        // For DeliveryScene where GET_DELIVERY_STATUS already had returnedTransaction, so we don't need to query GET_RETURNED_ITEMS again
        <ReturnedList props={props} data={{returnedItems: [returnedItem]}} />
      ) : (
        <ReturnedList props={props} />
      )}
    </Modal>
  );
};

/**
 * Purchase Data Table
 */
const tableStyles = createStyles({
  headerRow: {verticalAlign: 'top', height: 38},
  headerCell: {borderWidth: 0, color: DARK_GRAY},
  bodyRow: {verticalAlign: 'center', minHeight: 42},
  bodyCell: {
    borderWidth: 0,
    color: BLACK,
    padding: 4,
    maxWidth: 250,
  },
  footerRow: {
    borderTopWidth: 2,
    borderColor: GRAY8,
    borderTopStyle: 'solid',
    verticalAlign: 'bottom',
    height: 42,
  },
  footerCell: {color: BLACK},
});

const PurchaseSummary = ({props}: {props: PurchaseSummaryProps}) => {
  let {sumPriceProduct, creditPointUse, totalPrice, coupon} = props;
  const [valueWidth, setValueWidth] = useState(100);
  const setWidth = (width: number) => {
    if (width > valueWidth) {
      setValueWidth(width);
    }
  };

  return (
    <View>
      <Separator style={styles.separator} />
      <Text weight="bold" style={styles.bottomSpacing}>
        Ringkasan Pembayaran
      </Text>
      <View>
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          label="Total Harga"
          style={styles.bottomSpacing}
          value={sumPriceProduct}
        />
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          label="Biaya Ongkos Kirim"
          style={styles.bottomSpacing}
          value={0}
        />
        <SummaryField
          width={valueWidth}
          setWidth={setWidth}
          discount
          label="TVIP Credit Redeem"
          value={creditPointUse}
        />
        {coupon && (
          <SummaryField
            width={valueWidth}
            setWidth={setWidth}
            label={`KUPON ${coupon.couponType}`}
            style={styles.topSpacing}
            value={coupon.couponRewardValue}
            color={GREEN}
          />
        )}
      </View>
      <Separator style={styles.separator} />
      <SummaryField
        width={valueWidth}
        setWidth={setWidth}
        bold
        label="Total Pembayaran"
        value={totalPrice}
      />
    </View>
  );
};

const OrderTable = withStyles(tableStyles)((props: OrderTableProps) => {
  let {
    purchaseList,
    style,
    classes,
    purchaseSummary,
    invoiceNumber,
    bottleGuarantee,
    data: dataOutside,
    canceledOrders,
  } = props;
  let {sumPriceProduct, totalPrice, ...restPurchaseSummary} = purchaseSummary;
  let returnedValue: number = 0;

  const summedCanceledOrders = canceledOrders
    .flatMap(({cancelReason, deliveryItems}) =>
      deliveryItems.map((item) => ({...item, cancelReason})),
    )
    .reduce((prev, curr) => {
      const index = prev.findIndex(
        (item) => item.transactionItem.id === curr.transactionItem.id,
      );

      if (index !== -1) {
        return [
          ...prev.filter((_, i) => i !== index),
          {
            ...curr,
            quantityDelivery:
              prev[index].quantityDelivery + curr.quantityDelivery,
          },
        ];
      }

      return [...prev, curr];
    }, [] as (CanceledOrder['deliveryItems'][number] & {cancelReason: string})[]);

  return (
    <View style={style}>
      <Table padding="none">
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.headerCell}>
              <Text color={DARK_GRAY} size="small">
                Nama Produk
              </Text>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <Text color={DARK_GRAY} size="small">
                Jumlah
              </Text>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <Text color={DARK_GRAY} size="small">
                Unit
              </Text>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <Text color={DARK_GRAY} size="small">
                Harga Satuan
              </Text>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <Text color={DARK_GRAY} size="small">
                Subtotal
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchaseList.map(
            (
              {products, quantity, price, priceCut = 0, additionalProducts},
              index,
            ) => (
              <TableRow key={index} className={classes.bodyRow}>
                <TableCell className={classes.bodyCell}>
                  <View>
                    {products.map(({productData}, idx) => (
                      <Text key={idx}>{productData.title.toUpperCase()}</Text>
                    ))}
                    {[...(additionalProducts || [])].map(({name}, idx) => (
                      <Text key={idx}>{name.toUpperCase()}</Text>
                    ))}
                  </View>
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <Text>{quantity}</Text>
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <View>
                    {products.map(({productData}, idx) => (
                      <Text key={idx}>{productData.uom || '-'}</Text>
                    ))}
                  </View>
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <Text>Rp. {formatThousandSeparator(price - priceCut)}</Text>
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  <Text>
                    Rp. {formatThousandSeparator((price - priceCut) * quantity)}
                  </Text>
                </TableCell>
              </TableRow>
            ),
          )}
          {bottleGuarantee.map(({gallonType, qty, pricePerBottle}, index) => (
            <TableRow key={index} className={classes.bodyRow}>
              <TableCell className={classes.bodyCell}>
                <View>
                  <Text>{gallonType.toUpperCase()}</Text>
                  <Text color={DARK_GRAY} size="small">
                    ( Jaminan botol )
                  </Text>
                </View>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Text>{qty}</Text>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Text>GALON</Text>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Text>Rp. {formatThousandSeparator(pricePerBottle)}</Text>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <Text>Rp. {formatThousandSeparator(pricePerBottle * qty)}</Text>
              </TableCell>
            </TableRow>
          ))}
          {summedCanceledOrders.map(
            ({cancelReason, transactionItem, quantityDelivery}, index) => {
              const itemPrice =
                (transactionItem.price - transactionItem.priceCut) *
                quantityDelivery;
              totalPrice -= itemPrice;
              sumPriceProduct -= itemPrice;

              return (
                <TableRow key={index} className={classes.bodyRow}>
                  <TableCell className={classes.bodyCell}>
                    <View>
                      <Text>
                        {transactionItem.products[0].productData.title.toUpperCase()}
                      </Text>
                      <Text color={DARK_GRAY} size="small">
                        {`( ${cancelReason} )`}
                      </Text>
                    </View>
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <Text>- {quantityDelivery}</Text>
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <Text>{transactionItem.products[0].productData.uom}</Text>
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <Text>
                      Rp.{' '}
                      {formatThousandSeparator(
                        transactionItem.price - transactionItem.priceCut,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    <Text>- Rp. {formatThousandSeparator(itemPrice)}</Text>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
        {dataOutside ? (
          dataOutside.returnedItems.length > 0 ? (
            <Fragment>
              <TableBody>
                {dataOutside.returnedItems[0].returnedTransactionItems.map(
                  (
                    {transactionItem: {products, price, priceCut}, quantity},
                    index,
                  ) => {
                    returnedValue = (price - priceCut) * quantity;
                    sumPriceProduct -= returnedValue;
                    totalPrice -= returnedValue;
                    return (
                      <Fragment>
                        <TableRow key={index} className={classes.bodyRow}>
                          <TableCell className={classes.bodyCell}>
                            {products.map(({productData}) => (
                              <View key={productData.title}>
                                <Text>{productData.title.toUpperCase()}</Text>
                                <Text color={DARK_GRAY} size="small">
                                  ({' '}
                                  {dataOutside &&
                                    dataOutside.returnedItems[0].returnedTransactionItems[
                                      index
                                    ].returnReason.toUpperCase()}{' '}
                                  )
                                </Text>
                              </View>
                            ))}
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>{quantity}</Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            {products.map(({productData}) => (
                              <Text key={productData.uom}>
                                {productData.uom || '-'}
                              </Text>
                            ))}
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              Rp. {formatThousandSeparator(price - priceCut)}
                            </Text>
                          </TableCell>
                          <TableCell className={classes.bodyCell}>
                            <Text>
                              - Rp.{' '}
                              {formatThousandSeparator(
                                (price - priceCut) * quantity,
                              )}
                            </Text>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  },
                )}
              </TableBody>
              <TableFooter>
                <TableRow className={classes.footerRow}>
                  <TableCell className={classes.footerCell} colSpan={4}>
                    <Text>Total harga</Text>
                  </TableCell>
                  <TableCell className={classes.footerCell}>
                    <Text>Rp. {formatThousandSeparator(sumPriceProduct)}</Text>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Fragment>
          ) : null
        ) : (
          <Query<ReturnedListResult, ReturnedListVars>
            query={GET_RETURNED_ITEMS}
            variables={{invoiceNumber}}
            keyData="returnedItems"
            fetchPolicy="network-only"
            skip={!invoiceNumber}
          >
            {({data}) => {
              if (data) {
                if (data.returnedItems.length > 0) {
                  return (
                    <Fragment>
                      <TableBody>
                        {data.returnedItems[0].returnedTransactionItems.map(
                          (
                            {transactionItem: {products, price}, quantity},
                            index,
                          ) => {
                            returnedValue = price * quantity;
                            sumPriceProduct -= returnedValue;
                            totalPrice -= returnedValue;
                            return (
                              <Fragment>
                                <TableRow
                                  key={index}
                                  className={classes.bodyRow}
                                >
                                  <TableCell className={classes.bodyCell}>
                                    {products.map(({productData}) => (
                                      <View key={productData.title}>
                                        <Text>
                                          {productData.title.toUpperCase()}
                                        </Text>
                                        <Text color={DARK_GRAY} size="small">
                                          ({' '}
                                          {data.returnedItems[0].returnedTransactionItems[
                                            index
                                          ].returnReason.toUpperCase()}{' '}
                                          )
                                        </Text>
                                      </View>
                                    ))}
                                  </TableCell>
                                  <TableCell className={classes.bodyCell}>
                                    <Text>{quantity}</Text>
                                  </TableCell>
                                  <TableCell className={classes.bodyCell}>
                                    {products.map(({productData}) => (
                                      <Text key={productData.uom}>
                                        {productData.uom || '-'}
                                      </Text>
                                    ))}
                                  </TableCell>
                                  <TableCell className={classes.bodyCell}>
                                    <Text>
                                      Rp. {formatThousandSeparator(price)}
                                    </Text>
                                  </TableCell>
                                  <TableCell className={classes.bodyCell}>
                                    <Text>
                                      - Rp.{' '}
                                      {formatThousandSeparator(returnedValue)}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                              </Fragment>
                            );
                          },
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow className={classes.footerRow}>
                          <TableCell className={classes.footerCell} colSpan={4}>
                            <Text>Total harga</Text>
                          </TableCell>
                          <TableCell className={classes.footerCell}>
                            <Text>
                              Rp. {formatThousandSeparator(sumPriceProduct)}
                            </Text>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Fragment>
                  );
                }
                return null;
              }
              return null;
            }}
          </Query>
        )}
      </Table>
      <PurchaseSummary
        props={{sumPriceProduct, totalPrice, ...restPurchaseSummary}}
      />
    </View>
  );
});

/**
 * Information Field - Label and Value
 */
type FieldProps = {
  label: string;
  children?: string;
  style?: StyleProp<ViewStyle>;
};

const Field = ({label, children, style}: FieldProps) => (
  <View style={style}>
    <Text color={DARK_GRAY} size="small" style={styles.fieldLabel}>
      {label}
    </Text>
    <Text>{children}</Text>
  </View>
);

/**
 * Summary Field - Prices and Discounts
 */
type SummaryFieldProps = {
  value: number;
  discount?: boolean;
  width?: number;
  bold?: boolean;
  color?: string;
  setWidth?: (value: number) => void;
};

const SummaryField = ({
  label,
  style,
  value,
  discount,
  width,
  bold,
  color,
  setWidth,
}: FieldProps & SummaryFieldProps) => (
  <View style={[styles.row, style]}>
    <Text weight={bold ? 'bold' : 'reg'} color={color ? color : BLACK}>
      {label}
    </Text>
    <View style={[styles.row, styles.flex, styles.summaryValue]}>
      <Text
        style={styles.rightText}
        weight={bold ? 'bold' : 'reg'}
        color={color ? color : BLACK}
      >
        {discount ? '-Rp. ' : 'Rp. '}
      </Text>
      <Text
        onLayout={(evt) => setWidth && setWidth(evt.nativeEvent.layout.width)}
        style={[styles.rightText, {minWidth: width}]}
        weight={bold ? 'bold' : 'reg'}
        color={color ? color : BLACK}
      >
        {formatThousandSeparator(value <= 0 ? 0 : value)}
      </Text>
    </View>
  </View>
);

export default OrderDetailModal;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  separator: {
    backgroundColor: PRIMARY,
  },
  bottomSpacing: {
    paddingBottom: 20,
  },
  topSpacing: {
    paddingTop: 20,
  },
  fieldLabel: {
    paddingBottom: 5,
  },
  promoBox: {
    alignItems: 'center',
    borderColor: PRIMARY,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'dashed',
    paddingVertical: 10,
  },
  summaryValue: {
    justifyContent: 'flex-end',
  },
  rightText: {
    textAlign: 'right',
  },
  titleHeader: {
    flex: 1,
    textAlign: 'center',
  },
  descriptionHeader: {
    textAlign: 'center',
    color: 'black',
  },
});
