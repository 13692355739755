// const API_LOCAL = 'http://localhost:8000/graphql';

// const API_STAGING = 'http://staging.api.sqala.id:8000/graphql';
// const API_PRO = 'http://api.sqala.id:8000/graphql';

const API_URL =
  process.env.REACT_APP_API_GRAPHQL_URL || 'http://localhost:8000/graphql';

export const API_URI = API_URL;
export const WS_URI =
  process.env.REACT_APP_WEBSOCKET_URL ||
  API_URI.replace('http', 'ws').replace('/graphql', '');
