export function formatThousandSeparator(n: number) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function parseInteger(input: string) {
  let value = Number(input.replace(/\D/g, '').trim());
  return !isNaN(value) ? value : 0;
}

export function parseFloat(input: string) {
  let value = Number(input.replace(/[^\d\.]/g, '').trim());
  return !isNaN(value) ? value : 0;
}
