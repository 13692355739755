import React, {createRef, RefObject} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';

import {Icon, TextField} from '../core-ui';
import {WHITE, PRIMARY, BLACK} from '../constants/colors';

type State = {
  isSearchActive: boolean;
};

type Props = {
  value: string;
  placeholder?: string;
  onChangeText: (text: string) => void;
};

class SearchInput extends React.Component<Props, State> {
  _ref: RefObject<TextInput> = createRef();
  state: State = {
    isSearchActive: false,
  };

  componentDidUpdate() {
    let {isSearchActive} = this.state;
    if (isSearchActive && this._ref.current) {
      this._ref.current.focus();
    }
  }

  _handleOnBlur = () => {
    let {value} = this.props;
    let {isSearchActive} = this.state;
    if (value === '') {
      this.setState({isSearchActive: !isSearchActive});
    }
  };

  render() {
    let {value, placeholder, ...otherProps} = this.props;
    let {isSearchActive} = this.state;
    return (
      <View style={styles.container}>
        <TextField
          forwardedRef={this._ref}
          value={value}
          placeholder={placeholder || 'Cari..'}
          onBlur={this._handleOnBlur}
          // childrenPosition="left" // TODO: display icon on the left side
          style={{
            children: [
              styles.children,
              {backgroundColor: isSearchActive ? 'transparent' : 'inherit'},
            ],
            textField: isSearchActive
              ? styles.searchActive
              : styles.searchNotActive,
            inputContainer: !isSearchActive ? {borderWidth: 0} : {},
          }}
          {...otherProps}
        >
          <Icon
            color={isSearchActive ? PRIMARY : BLACK}
            name="search"
            size="small"
            onPress={() => {
              this.setState({isSearchActive: !isSearchActive});
            }}
          />
        </TextField>
      </View>
    );
  }
}

export default SearchInput;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    margin: 5,
  },
  searchNotActive: {
    width: 20,
    transitionDuration: '0.5s',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease-out',
  },
  searchActive: {
    width: 220,
    backgroundColor: WHITE,
    borderRadius: 4,
    transitionDuration: '0.5s',
    transitionProperty: 'width',
    transitionTimingFunction: 'ease',
  },
  children: {
    top: -1,
    right: -1,
    height: 33,
    position: 'absolute',
  },
});
