import React from 'react';

let deliveryMan = ({fill, size}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-300 -300 1100 1100"
    version="1.1"
  >
    <g fill={fill}>
      <path
        d="M483.462,493.045l-40.559-148.39c-0.912-3.337-2.949-6.257-5.767-8.264c-64.291-45.785-43.216-30.777-98.177-69.915
			c20.862-21.438,32.888-50.42,32.888-80.758c0-60.48,0.402-63.79-1.371-74.824C369.821,47.582,321.836,0,258.332,0h-4.664
			C190.165,0,142.18,47.582,141.525,110.895c-1.773,11.034-1.371,14.341-1.371,74.824c0,30.336,12.026,59.318,32.889,80.758
			c-69.552,49.528-28.912,20.587-98.178,69.915c-2.818,2.007-4.856,4.927-5.768,8.264l-40.559,148.39
			C25.931,502.585,33.124,512,43.007,512h197.994V353.483l15-9.855l15,9.855V512h197.994
			C478.883,512,486.068,502.577,483.462,493.045z M246.205,48.602h19.591c8.284,0,15,6.716,15,15s-6.716,15-15,15h-19.591
			c-8.284,0-15-6.716-15-15S237.921,48.602,246.205,48.602z M241.002,317.587l-32.995,21.679l-44.013-29.516l33.702-24
			c13.776,8.035,28.656,12.842,43.306,14.728V317.587z M256.001,271.44c-47.645,0-85.847-38.667-85.847-85.721
			c-0.001-10.742-0.013-19.632-0.023-27.076l8.064,11.773c2.796,4.083,7.426,6.523,12.375,6.523h130.861
			c4.948,0,9.578-2.44,12.375-6.523l8.064-11.773c-0.01,7.443-0.022,16.334-0.022,27.076
			C341.848,232.352,304.07,271.44,256.001,271.44z M271,317.587v-17.11c15.43-1.984,30.192-7.078,43.305-14.728l33.702,24
			l-44.013,29.516L271,317.587z M383.428,448.093H330.83c-8.284,0-15-6.716-15-15s6.716-15,15-15h52.598c8.284,0,15,6.716,15,15
			S391.712,448.093,383.428,448.093z"
      />
    </g>
  </svg>
);

let truck = ({fill, size}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-300 -300 1100 1100"
    version="1.1"
  >
    <g fill={fill}>
      <path
        d="M469.8,298.8v-52.4c0-19.9-16-35.9-35.9-35.9l-24.8-3.9l-42.5-64c-0.6-0.5-1.1-1.7-1.7-2.2c-6.6-8.8-17.1-14.9-29.3-14.9
		H255v-22.3c0-11.3-8.4-20.5-18.8-20.5H18.8C8.4,82.7,0,91.9,0,103.2v140.1v76v4.9v21c0,11.6,9.4,20.4,20.4,20.4h29.3
		c6.6,23.2,27.6,39.7,53,39.7s46.4-17.1,53-39.7h66.8h113.7c6.6,23.2,27.6,39.7,53,39.7s46.4-17.1,53-39.7h25.4
		c11.6,0,20.4-9.4,20.4-20.4v-25.9C488,308.7,479.7,299.9,469.8,298.8z M102.7,377.7c-15.5,0-27.6-12.7-27.6-27.6
		c0-14.9,12.7-27.6,27.6-27.6c15.5,0,27.6,12.7,27.6,27.6C130.3,365,118.2,377.7,102.7,377.7z M292.8,208.7c-1.4,0-2.6-1.1-2.6-2.6
		v-50.7c0-1.4,1.1-2.6,2.6-2.6h43.8c0.9,0,1.7,0.4,2.1,1.1l34.1,50.7c1.1,1.7-0.1,4-2.1,4h-77.9V208.7z M389.2,377.7
		c-15.5,0-27.6-12.7-27.6-27.6c0-14.9,12.7-27.6,27.6-27.6c15.5,0,27.6,12.7,27.6,27.6C416.8,365,404.1,377.7,389.2,377.7z"
      />
    </g>
  </svg>
);

let businessMan = ({fill, size}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-300 -300 1100 1100"
    version="1.1"
  >
    <g fill={fill}>
      <path
        d="M312.6,319.797c0-1.9,0.1-3.8,0.4-5.7c-29.1-13.3-45.7-24.2-45.7-24.2l-37.3,118l-5.1,16l-16.7-47.4
			c38.4-53.6-2.9-56.1-10.1-56.2l0,0H198h-0.1h-0.1h-0.1l0,0c-7.2,0.1-48.5,2.6-10.1,56.2l-16.7,47.4l-5.1-16l-37.3-118
			c0,0-29,18.9-77.4,37.4c-53.3,19.4-50,63.3-51.1,145.6h197.7h0.5h120.1c-3.5-5.7-5.5-12.4-5.5-19.5v-133.6H312.6z"
      />
      <path
        d="M488.1,262.097c-2.2-5.2-8.8-14.1-28.9-14.3c-0.2,0-0.4,0-0.6,0c-12.4,0-27.1,3.3-41.6,9.3c-2.6,1.1-5.2,2.3-7.7,3.5
			l-0.1-0.1c-8.3-8.1-21.5-8.1-29.8,0l-40.7,39.4c-5.4,5.2-8.4,12.4-8.4,19.9v133.7c0,10.8,8.7,19.5,19.5,19.5h88.8
			c10.8,0,19.5-8.7,19.5-19.5v-133.7c0-3.8-0.8-7.6-2.3-11c8.6-4.8,16.1-10.3,21.9-16.1C491.8,278.297,490.3,267.297,488.1,262.097z
			 M434.9,300.597c-8.5,3.5-17.2,6-25.1,7.2c0,0.3,0.1,0.7,0.1,1c0,8.9-7.2,16.2-16.2,16.2c-8.9,0-16.2-7.2-16.2-16.2
			c0-8.9,7.2-16.2,16.2-16.2c5.3,0,10,2.6,13,6.5c7.5-1.3,15.6-3.6,23.6-6.9c2.8-1.2,5.4-2.4,7.9-3.6l-16.3-15.9
			c0.5-0.2,0.9-0.4,1.4-0.6c12.4-5.1,25.2-8,35.3-8c0.2,0,0.3,0,0.5,0c9.7,0.1,13.3,2.7,13.9,4.1
			C474.7,272.597,463.9,288.697,434.9,300.597z"
      />
      <path
        d="M114.8,215.797c4.3,27.8,25.7,63.2,61,75.7c14.5,5.1,30.4,5.1,44.8-0.1c34.7-12.5,56.9-47.8,61.2-75.6
			c4.7-0.4,10.8-6.9,17.4-30.3c9.1-32-0.6-36.7-8.7-36c1.6-4.4,2.7-8.8,3.5-13.2c13.8-82.9-27-85.8-27-85.8s-6.8-13-24.6-22.9
			c-11.9-7.1-28.6-12.6-50.5-10.7c-7.1,0.3-13.8,1.7-20.1,3.8l0,0c-8.1,2.7-15.4,6.7-22.1,11.3c-8.2,5.2-15.9,11.6-22.8,18.8
			c-10.8,11-20.4,25.3-24.6,43.2c-3.5,13.3-2.7,27.2,0.2,42.2l0,0c0.8,4.4,2,8.8,3.5,13.2c-8.1-0.8-17.8,4-8.7,36
			C104,208.897,110.1,215.497,114.8,215.797z"
      />
    </g>
  </svg>
);

let order = ({fill, size}) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-300 -300 1120 1120"
  >
    <g fill={fill}>
      <path d="m106.980469 190.628906-12.683594-15.164062-23.015625 19.246094 32.601562 38.976562 54.214844-48.585938-20.019531-22.34375zm0 0" />
      <path d="m180.667969 187h115.976562v30h-115.976562zm0 0" />
      <path d="m106.980469 276.886719-12.683594-15.164063-23.015625 19.246094 32.601562 38.976562 54.214844-48.585937-20.019531-22.339844zm0 0" />
      <path d="m180.667969 273.257812h115.976562v30h-115.976562zm0 0" />
      <path d="m106.980469 363.144531-12.683594-15.164062-23.015625 19.246093 32.601562 38.976563 54.214844-48.585937-20.019531-22.339844zm0 0" />
      <path d="m180.667969 359.519531h115.976562v30h-115.976562zm0 0" />
      <path d="m302.546875 0h-30v30.546875h-38.695313v-30.546875h-30v30.546875h-38.703124v-30.546875h-30v30.546875h-38.695313v-30.546875h-30v30.546875h-66.453125v481.453125h369v-481.453125h-66.453125zm36.453125 60.546875v421.453125h-309v-421.453125h36.453125v30.542969h30v-30.542969h38.695313v30.542969h30v-30.542969h38.699218v30.542969h30v-30.542969h38.699219v30.542969h30v-30.542969zm0 0" />
      <path d="m404.4375 26.480469v412.726562l44.925781 72.792969h17.707031l44.929688-72.796875v-412.722656zm77.5625 30v52.109375h-47.5625v-52.109375zm-47.5625 82.109375h47.5625v244.425781h-47.5625zm23.78125 330.636718-23.78125-38.53125v-17.679687h47.5625v17.675781zm0 0" />
    </g>
  </svg>
);

let noData = () => {
  return (
    <svg
      width="64px"
      height="56px"
      viewBox="0 0 64 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shape</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="2.-Phase-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Signup-6-Complete-First-Login-2"
          transform="translate(-728.000000, -428.000000)"
          fill="#8FA0BD"
          fillRule="nonzero"
        >
          <g id="analytics-(2)" transform="translate(728.000000, 428.000000)">
            <path
              d="M63,38 L58,38 L58,2 L63,2 C63.5527916,2 64,1.5527 64,1 C64,0.4473 63.5527916,0 63,0 L1,0 C0.4472918,0 0,0.4473 0,1 C0,1.5527 0.4472918,2 1,2 L6,2 L6,38 L1,38 C0.4472917,38 0,38.4473 0,39 C0,39.5527 0.4472918,40 1,40 L15,40 L15,54 L11,54 C10.4472914,54 10,54.4473 10,55 C10,55.5527 10.4472914,56 11,56 L21,56 C21.5527916,56 22,55.5527 22,55 C22,54.4473 21.5527916,54 21,54 L17,54 L17,40 L49,40 L49,54 L45,54 C44.4472923,54 44,54.4473 44,55 C44,55.5527 44.4472923,56 45,56 L55,56 C55.5527916,56 56,55.5527 56,55 C56,54.4473 55.5527916,54 55,54 L51,54 L51,40 L63,40 C63.5527916,40 64,39.5527 64,39 C64,38.4473 63.5527916,38 63,38 Z M56,38 L8,38 L8,2 L56,2 L56,38 Z M18.4111919,28.1338005 C18.6669998,28.1338005 18.9228916,28.0360985 19.1181907,27.8407993 L29.0927905,17.8672008 L35.1630896,26.3642005 C35.3574904,26.637699 35.6826895,26.8124999 36.0107916,26.7821998 C36.3456915,26.7714996 36.6523893,26.5928001 36.8281897,26.3076 L47.6376913,8.7422008 C47.9267919,8.2715005 47.7802885,7.655301 47.3105886,7.3662013 C46.8368909,7.0752009 46.2226903,7.2226008 45.9345891,7.6934012 L35.9140892,23.9755993 L30.0341911,15.7441006 C29.8632908,15.5049 29.5956916,15.3525009 29.3027916,15.3281002 C29.0107918,15.3115005 28.7216911,15.4101009 28.513691,15.6182003 L17.704092,26.4267006 C17.3134918,26.8174 17.3134918,27.4501991 17.704092,27.8407993 C17.8993912,28.0360985 18.1552906,28.1338005 18.4111919,28.1338005 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SVG = {
  deliveryMan,
  truck,
  businessMan,
  order,
  noData,
};
