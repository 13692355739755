import React, {Component} from 'react';

import {StyleSheet, View} from 'react-native';

import ApolloClient from 'apollo-client';
import {OrderList, RouteOrder} from '.';
import {NAVY_BLUE} from '../../../../constants/colors';
import {Tab, Tabs, Text} from '../../../../core-ui';
import {
  DriverOrder,
  GET_RIT_SELECTED_ORDERS,
  RITSelectedOrderParams,
  RITSelectedOrderResult,
} from '../../../../graphql/queries';
import {
  SelectedDriver,
  TotalRemainingOrderRefetch,
} from '../../AssignDriverListScene';

type OwnProps = {
  selectedDriver: SelectedDriver;
  editRITID?: string;
  closeAssignModal: () => void;
  totalRefetchQuery: TotalRemainingOrderRefetch;
};

type State = {
  selectedTab: number;
  editRITID?: string;
  selectedPurchases: Array<DriverOrder>;
  isCapacityLimit: boolean;
  selectedType: string;
  totalBox: number;
  totalGallon: number;
  totalOrder: number;
};

type Props = OwnProps & {
  apolloClient: ApolloClient<any>;
  role: string;
};

export class AssignRIT extends Component<Props, State> {
  state: State = {
    selectedTab: 0,
    selectedPurchases: [],
    editRITID: this.props.editRITID,
    isCapacityLimit: false,
    selectedType: '',
    totalOrder: 0,
    totalBox: 0,
    totalGallon: 0,
  };

  componentDidMount() {
    this._checkRITSelectedOrder();
  }

  render() {
    return (
      <View style={styles.root}>
        {this._renderProductCount()}
        {this._renderTabs()}
      </View>
    );
  }

  _renderProductCount = () => {
    let {
      totalOrder,
      selectedPurchases,
      selectedType,
      totalBox,
      totalGallon,
    } = this.state;
    let {
      selectedDriver: {maxCapacity},
    } = this.props;
    const totalSelected = selectedPurchases
      .map(({totalOrder}) =>
        totalOrder.reduce((prev, curr) => {
          return prev + curr.quantity * curr.unitQuantity;
        }, 0),
      )
      .reduce((prev, curr) => prev + curr, 0);

    const countSelectedUom = (uom: 'GALON' | 'BOX') => {
      return selectedPurchases
        .map(({totalOrder}) =>
          totalOrder
            .filter((order) => order.uom === uom)
            .reduce((prev, curr) => {
              return prev + curr.quantity * curr.unitQuantity;
            }, 0),
        )
        .reduce((prev, curr) => prev + curr, 0);
    };

    const totalBoxSelected = countSelectedUom('BOX');
    const totalGallonSelected = countSelectedUom('GALON');

    const orderSelected =
      totalSelected > maxCapacity ? maxCapacity : totalSelected;
    const orderBox = totalBox > maxCapacity ? maxCapacity : totalBoxSelected;
    const orderGallon =
      totalGallon > maxCapacity ? maxCapacity : totalGallonSelected;

    const orderInfo = (() => {
      if (selectedType !== 'box dan galon') {
        return (
          <Text>
            Total Pesanan :{' '}
            {
              <Text color={NAVY_BLUE} weight="bold">
                {totalOrder - orderSelected}
              </Text>
            }
            {' Pesanan'}
          </Text>
        );
      }

      return (
        <Text>
          Total Pesanan :{' '}
          {
            <Text color={NAVY_BLUE} weight="bold">
              {totalGallon - orderGallon}
            </Text>
          }{' '}
          GALON -{' '}
          {
            <Text color={NAVY_BLUE} weight="bold">
              {totalBox - orderBox}
            </Text>
          }{' '}
          BOX
        </Text>
      );
    })();

    return <View style={styles.productCountContainer}>{orderInfo}</View>;
  };

  _checkRITSelectedOrder = async () => {
    let {apolloClient, editRITID} = this.props;
    try {
      if (editRITID) {
        let {data, errors, loading} = await apolloClient.query<
          RITSelectedOrderResult,
          RITSelectedOrderParams
        >({
          query: GET_RIT_SELECTED_ORDERS,
          variables: {
            where: {id: editRITID},
          },
          fetchPolicy: 'network-only',
        });
        if (data.ritSelectedOrders && !errors && !loading) {
          this.setState({selectedPurchases: data.ritSelectedOrders});
        }
      }
    } catch (error) {
      return; // TODO: handle error
    }
  };

  _onMixOrderFilterChange = (type: string) => {
    this.setState({
      selectedType: type,
      selectedPurchases: [],
    });
  };

  _renderTabs = () => {
    let {
      selectedTab,
      selectedPurchases,
      editRITID,
      isCapacityLimit,
    } = this.state;
    let {selectedDriver, closeAssignModal, totalRefetchQuery} = this.props;
    return (
      <Tabs
        selectedIndex={selectedTab}
        onChange={(_e, index) => {
          this.setState({selectedTab: index});
        }}
      >
        <Tab label="Pilih Pesanan">
          <OrderList
            selectedDriver={selectedDriver}
            onNextPress={() => {
              this.setState({selectedTab: 1});
            }}
            editRITID={editRITID}
            onOrderListQueryComplete={this._onOrderListQueryComplete}
            onSelectedChange={this._onSelectedChange}
            selectedPurchases={selectedPurchases}
            setCapacityLimit={this._setCapacityLimit}
            isCapacityLimit={isCapacityLimit}
            role={this.props.role}
            onMixOrderFilterChange={this._onMixOrderFilterChange}
          />
        </Tab>
        <Tab label="Rute Pengiriman">
          <RouteOrder
            totalRefetchQuery={totalRefetchQuery}
            selectedPurchases={selectedPurchases}
            editRITID={editRITID}
            driverNIK={selectedDriver.driverNIK}
            closeAssignModal={closeAssignModal}
          />
        </Tab>
      </Tabs>
    );
  };
  _setCapacityLimit = (isCapacityLimit: boolean) => {
    this.setState({
      isCapacityLimit,
    });
  };
  _onSelectedChange = (selectedPurchases: Array<DriverOrder>) => {
    this.setState({selectedPurchases});
  };

  _onOrderListQueryComplete = ({
    totalBox,
    totalGallon,
    totalOrder,
  }: {
    totalBox: number;
    totalGallon: number;
    totalOrder: number;
  }) => {
    this.setState({
      totalOrder,
      totalBox,
      totalGallon,
    });
  };
}

const styles = StyleSheet.create({
  root: {flex: 1},
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  productCountContainer: {
    height: 30,
    alignItems: 'flex-end',
  },
  tabContent: {paddingTop: 20},
});

export default AssignRIT;
