import gql from 'graphql-tag';
import {
  canceledOrders,
  coupon,
  returnedTransactionItems,
  transactionItems,
  user,
} from '../relationalFragments';
import {Driver} from './driverQuery';
import {ReturnedTransactionItem} from './invoiceQuery';
import {Coupon, DeliveryItem, Order, OrderStatus} from './orderQuery';
// import { RITOrderByInput } from './ritQuery';

export type AssignedOrder = {
  id: string;
  deliveryItems: Array<DeliveryItem>;
  status: OrderStatus;
  cancelReason: string;
  pendingReason: string;
  doNumber: string;
  active: boolean;
  coupon: Coupon;
};

export type RITTransaction = {
  rit: {
    id: string;
    driver: Driver;
  };
  assignedOrder: AssignedOrder;
  transaction: Order;
  returnedTransactionItems: Array<ReturnedTransactionItem>;
};

export type RITTransactionWithCount = {
  ritTransactions: Array<RITTransaction>;
  count: number;
};

export type DeliveryListResult = {deliveries: RITTransactionWithCount};
export type DeliveryListParams = {
  ritWhere: ObjectKey;
  transactionWhere: ObjectKey;
  skip: number;
  first: number;
};

export type QueryWhere = 'RIT_WHERE' | 'TRANSACTION_WHERE';

const DELIVERY_RETURN_VARIABLES = `
  ritTransactions {
    rit {
      id
      completed
      driver {
        id
        driverCode
        driverName
      }
    }
    assignedOrder {
      id
      doNumber
      status
      cancelReason
      bottleGuarantee {
        gallonType
        qty
        pricePerBottle
      }
      creditPointUse
      ${coupon}
      ${canceledOrders}
      pendingReason
      deliveryItems {
        id
        deliveryType
        quantityDelivery
        transactionItem {
          id
          price
          priceCut
          products {
            productData {
              productID
              title
              nickname
              description
              photo
              dimension
              uom
              sku
            }
            unitQuantity
          }
          quantity
        }
      }
    }
    transaction {
      poNumber
      soNumber
      invoiceNumber
      createdAt
      deliveryDate
      canceledDate
      deliveredDate
      cancelReason
      paymentType
      paymentStatus
      creditPointUse
      ${coupon}
      ${user}
      ${transactionItems}
      ${canceledOrders}
      bottleGuarantee {
        gallonType
        qty
        pricePerBottle
      }
    }
    ${returnedTransactionItems}
  }
  count
`;

export const GET_DELIVERY_STATUS = gql`
  query getDeliveries(
    $ritWhere: RITWhereInput,
    $transactionWhere: TransactionWhereInput,
    $first: Int,
    $skip: Int) {
    deliveries(
      ritWhere: $ritWhere,
      transactionWhere: $transactionWhere,
      first: $first,
      skip: $skip,
    ){
      ${DELIVERY_RETURN_VARIABLES}
    }
  }
`;
