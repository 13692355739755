import React from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';

import {SEPARATOR, PRIMARY, BLACK} from '../constants/colors';

type Props = {
  thickness?: 'light' | 'regular' | 'thick' | 'x-thick';
  style?: StyleProp<ViewStyle>;
  secondary?: boolean;
  isVertical?: boolean;
};

const THICKNESS = {light: 1, regular: 2, thick: 4, 'x-thick': 6};

export default function Separator({
  thickness = 'regular',
  style,
  secondary,
  isVertical = false,
}: Props) {
  let thicknessStyling = thickness && {height: THICKNESS[thickness]};
  let widthBorder = thickness && {borderLeftWidth: THICKNESS[thickness]};

  if (isVertical) {
    return <View style={[widthBorder, styles.vertical]} />;
  }

  return (
    <View
      style={[
        styles.root,
        thicknessStyling,
        secondary && styles.secondary,
        style,
      ]}
    />
  );
}

let styles = StyleSheet.create({
  root: {
    marginVertical: 20,
    backgroundColor: SEPARATOR,
  },
  secondary: {
    backgroundColor: PRIMARY,
  },
  vertical: {
    top: 0,
    bottom: 0,
    width: 10,
    borderLeftColor: BLACK,
    position: 'absolute',
  },
});
