import React, {Component, Fragment} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  StyleProp,
  ViewStyle,
  TextStyle,
  LayoutChangeEvent,
  FlatList,
  ListRenderItemInfo,
} from 'react-native';

import {Text, Icon, Loading} from '.';
import {WHITE, BLACK, DROPDOWN, DARK_GRAY} from '../constants/colors';
import BOX_SHADOW from '../constants/boxShadow';

export type Option<T = string> = {value: T; label: string};

export type DropdownProps<T = string> = {
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  options: Array<Option<T>>;
  placeholder: string;
  selectedOption: Nullable<T>;
  searchable?: boolean;
  label?: string;
  labelHorizontal?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  isLoading?: boolean;
  onChange: (selectedOption: Nullable<Option<T>>) => void;
};

type State = {
  isDropdownOpened: boolean;
  dropdownWidth: number;
  hoveredIndex: Nullable<number>;
  inputText: string;
};

export default class Dropdown<T = string> extends Component<
  DropdownProps<T>,
  State
> {
  _inputRef?: TextInput;

  state = {
    isDropdownOpened: false,
    dropdownWidth: 0,
    hoveredIndex: null,
    inputText: '',
  };

  render() {
    let {
      searchable,
      style,
      label,
      labelHorizontal,
      isLoading,
      ...otherProps
    } = this.props;
    let {isDropdownOpened} = this.state;
    return (
      <View style={[labelHorizontal && styles.root, style]} {...otherProps}>
        {label && this._renderLabel()}
        <View
          onLayout={this._setLayout}
          style={labelHorizontal && styles.wrapperTouchable}
        >
          <TouchableOpacity onPress={this._onDropdownPress}>
            {isLoading ? (
              <Loading size="small" style={styles.loading} />
            ) : searchable ? (
              this._renderSearchable()
            ) : (
              this._renderNormalDropdown()
            )}
          </TouchableOpacity>
          {isDropdownOpened && this._renderOptions()}
        </View>
      </View>
    );
  }

  _renderLabel() {
    let {label, labelStyle, labelHorizontal} = this.props;
    return (
      <View
        style={
          labelHorizontal ? styles.labelWrapperHorizontal : styles.labelWrapper
        }
      >
        <Text size="small" style={[styles.label, labelStyle]}>
          {label}
        </Text>
      </View>
    );
  }

  _renderSearchable() {
    let {backgroundColor, placeholder, onChange} = this.props;
    let {isDropdownOpened, inputText} = this.state;
    let displayedValue = this._getDisplayedValue(inputText);
    return (
      <TextInput
        placeholder={placeholder}
        placeholderTextColor={DROPDOWN.PLACEHOLDER}
        style={[
          styles.textInput,
          backgroundColor ? {backgroundColor} : {},
          isDropdownOpened && styles.activeDropdown,
        ]}
        value={displayedValue}
        onChangeText={(text) => {
          onChange(null);
          this.setState({inputText: text});
        }}
      />
    );
  }

  _renderNormalDropdown() {
    let {selectedOption, placeholder, backgroundColor} = this.props;
    let {isDropdownOpened} = this.state;
    let displayedValue = this._getDisplayedValue(null);
    return (
      <Fragment>
        <View
          style={[
            styles.dropdownButton,
            backgroundColor ? {backgroundColor} : {},
            isDropdownOpened && styles.dropdownOpened,
          ]}
        >
          <Text
            size="small"
            color={selectedOption != null ? BLACK : DROPDOWN.PLACEHOLDER}
            numberOfLines={1}
          >
            {selectedOption != null ? displayedValue : placeholder}
          </Text>
          <Icon
            name="expand_more"
            size="small"
            containerStyle={
              isDropdownOpened ? styles.iconOpened : styles.iconClosed
            }
            color={isDropdownOpened ? DROPDOWN.ACTIVE : DARK_GRAY}
          />
        </View>
        <TextInput
          ref={this._setRef}
          style={StyleSheet.flatten(styles.dummyInput)}
        />
      </Fragment>
    );
  }

  _renderOptions() {
    let {options, searchable} = this.props;
    let {inputText, dropdownWidth} = this.state;
    let hasOptions = !!options.length;
    let filteredOptions = this._filterOptionsByInput(inputText, options);
    return (
      <FlatList
        style={[
          styles.optionsWrapper,
          {width: dropdownWidth},
          searchable &&
            searchable &&
            inputText === '' &&
            !hasOptions && {borderWidth: 0},
        ]}
        data={filteredOptions}
        renderItem={this._renderOption}
        ListEmptyComponent={NoData}
        keyExtractor={this._keyExtractor}
      />
    );
  }

  _keyExtractor = (item: Option<T>) => item.label;

  _renderOption = ({item: option, index}: ListRenderItemInfo<Option<T>>) => {
    let {selectedOption} = this.props;
    let {hoveredIndex} = this.state;
    let {label, value} = option;
    let isCurrentlyHovered = index === hoveredIndex;
    return (
      <View
        key={index}
        style={[styles.option, isCurrentlyHovered && styles.optionHovered]}
        onMouseDown={() => {
          this._onSelectedOption(option);
          this._onCloseModal();
        }}
        onMouseOver={() => this._onMouseOver(index)}
        onMouseLeave={() => this._onMouseOver(null)}
      >
        <Text size="small" color={BLACK}>
          {label}
        </Text>
        {value === selectedOption && (
          <Icon name="check" size="small" color={DROPDOWN.ACTIVE} />
        )}
      </View>
    );
  };

  _getDisplayedValue = (inputText: Nullable<string>) => {
    let {selectedOption, options} = this.props;
    if (selectedOption != null && options.length > 0) {
      let selectedElement = options.find(
        (option) => option.value === selectedOption,
      );
      if (selectedElement) {
        return selectedElement.label;
      }
    }
    return inputText || '';
  };

  _filterOptionsByInput = (text: string, options: Array<Option<T>>) =>
    options.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase()),
    );

  _setLayout = (e: LayoutChangeEvent) =>
    this.setState({dropdownWidth: e.nativeEvent.layout.width});

  _setRef = (inputRef: TextInput) => (this._inputRef = inputRef);

  _onMouseOver = (index: Nullable<number>) =>
    this.setState({hoveredIndex: index});

  _onDropdownPress = () => {
    if (!this.props.searchable) {
      this._focus();
    }
    if (this.state.isDropdownOpened) {
      this._onCloseModal();
    }
    if (!this.props.isLoading && !this.state.isDropdownOpened) {
      this.setState({isDropdownOpened: true});
    }
  };

  _onSelectedOption = (option: Option<T>) => this.props.onChange(option);

  _onCloseModal = () => this.setState({isDropdownOpened: false});

  _focus = () => this._inputRef && this._inputRef.focus();
}

function NoData() {
  return (
    <View style={styles.option}>
      <Text size="small" color={BLACK}>
        Data tidak tersedia.
      </Text>
    </View>
  );
}

const HEIGHT = 33;

let styles = StyleSheet.create({
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: DROPDOWN.BACKGROUND,
    paddingRight: 5,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT,
  },
  optionsWrapper: {
    position: 'absolute',
    borderRadius: 4,
    borderColor: DROPDOWN.ACTIVE,
    top: 35,
    boxShadow: BOX_SHADOW,
    backgroundColor: WHITE,
    maxHeight: 170,
    borderWidth: 1,
  },
  option: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingVertical: 10,
    cursor: 'pointer',
  },
  optionHovered: {
    backgroundColor: DROPDOWN.HOVERED,
  },
  dummyInput: {
    position: 'relative',
    borderWidth: 0,
    fontSize: 'inherit',
    width: 1,
    height: 0,
    outline: '0',
    padding: 0,
    opacity: 0,
  },
  textInput: {
    backgroundColor: DROPDOWN.BACKGROUND,
    padding: 10,
    paddingLeft: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: DROPDOWN.BORDER,
    height: HEIGHT,
    outline: '0',
  },
  dropdownOpened: {
    borderColor: DROPDOWN.ACTIVE,
    borderWidth: 1,
  },
  label: {color: DROPDOWN.LABEL},
  iconOpened: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    transform: [{rotate: '180deg'}],
    transitionProperty: 'transform',
  },
  iconClosed: {
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease',
    transform: [{rotate: '0deg'}],
    transitionProperty: 'transform',
  },
  labelWrapper: {
    paddingVertical: 10,
  },
  labelWrapperHorizontal: {
    flex: 1,
    justifyContent: 'center',
  },
  loading: {
    paddingVertical: 6.5,
  },
  wrapperTouchable: {flex: 2},
  root: {
    flexDirection: 'row',
    marginBottom: 32,
  },
  activeDropdown: {
    borderWidth: 1,
    borderColor: DROPDOWN.ACTIVE,
  },
});
