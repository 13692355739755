import React, {Fragment} from 'react';
import {compose, graphql} from 'react-apollo';
import {StyleSheet, View} from 'react-native';
import {
  ErrorScreen,
  OrderDetailModal,
  Popover,
  PopoverTableCard,
  Query,
  RowsPerPage,
  Table,
} from '../../components';
import {BLACK, GRAY, NAVY_BLUE, PRIMARY} from '../../constants/colors';
import {Icon, Text} from '../../core-ui';
import {SearchState} from '../../graphql/localState';
import {
  AssignedOrder,
  DeliveryListParams,
  DeliveryListResult,
  GET_DELIVERY_STATUS,
  GET_SEARCH_STATE,
  Order,
  QueryWhere,
  ReturnedItem,
  SearchStateProps,
} from '../../graphql/queries';
import {fetchMoreItems, refetchItems} from '../../helpers';

type State = {
  page: number;
  resetPage: boolean;
  rowsPerPage: RowsPerPage;
  isInvoiceVisible: boolean;
  actionData: Nullable<Order & AssignedOrder>;
  returnedData: Nullable<ReturnedItem>;
  deliveries: any[];
};

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = SearchStateProps & SceneProps;

export class RejectedOrderList extends React.Component<Props, State> {
  state: State = {
    page: 0,
    rowsPerPage: 10,
    resetPage: false,
    isInvoiceVisible: false,
    actionData: null,
    returnedData: null,
    deliveries: [],
  };

  render() {
    return <Fragment>{this._renderTable()}</Fragment>;
  }

  _getQueryWhere = (type: QueryWhere) => {
    let {
      searchStateQuery: {searchState},
    } = this.props;
    if (searchState) {
      let searchText = searchState.searchedString.toLowerCase();
      if (type === 'RIT_WHERE') {
        return {
          AND: [
            {
              assignedOrder_some: {
                status_in: [
                  'CANCELED_BY_ADMIN',
                  'CANCELED_BY_CUSTOMER',
                  'CANCELED_BY_DRIVER',
                ],
              },
            },
            {
              OR: [
                {
                  assignedOrder_some:
                    searchText === ''
                      ? {
                          doNumber: null,
                        }
                      : {
                          doNumber_contains: searchText,
                        },
                },
                {
                  driver: {
                    OR: [
                      {driverCode_contains: searchText},
                      {driverName_contains: searchText},
                    ],
                  },
                },
              ],
            },
          ],
        };
      } else if (type === 'TRANSACTION_WHERE') {
        return {
          OR: [
            {poNumber_contains: searchText},
            {soNumber_contains: searchText},
            {invoiceNumber_contains: searchText},
            {
              user: {
                OR: [
                  searchText === ''
                    ? {szID: null}
                    : {szID_contains: searchText},
                  {storeName_contains: searchText},
                  {storeTelephone_contains: searchText},
                ],
              },
            },
          ],
        };
      }
    }
    return {};
  };

  _renderTableContent = (
    ritTransactions: any,
    count: number,
    resetPage: any,
    setResetPage: any,
    loading: any,
    page: any,
    rowsPerPage: any,
    fetchMore: any,
    dataKey: any,
  ) => {
    return (
      <Table
        data={ritTransactions || []}
        dataCount={count || 0}
        resetPage={resetPage}
        setResetPage={setResetPage}
        isLoading={loading}
        page={page}
        onChangePage={(nextPage) => this.setState({page: nextPage})}
        rowsPerPage={rowsPerPage}
        structure={{
          poNumber: {
            alias: 'transaction.poNumber',
            headerTitle: 'No. PO',
          },
          doNumber: {
            alias: 'assignedOrder.doNumber',
            headerTitle: 'No. DO',
          },
          invoiceNumber: {
            alias: 'transaction.invoiceNumber',
            headerTitle: 'Invoice',
          },
          driverCode: {
            alias: 'rit.driver.driverCode',
            headerTitle: 'Kode Driver',
          },
          driverName: {
            alias: 'rit.driver.driverName',
            headerTitle: 'Nama Driver',
          },
          totalOrder: {
            headerTitle: 'Total Pesanan',
            render: ({assignedOrder: {deliveryItems}}) => (
              <View style={styles.customField}>
                <Popover
                  popoverTrigger={
                    <Text
                      size="small"
                      weight="bold"
                      color={NAVY_BLUE}
                      style={{letterSpacing: 1.5}}
                    >
                      {deliveryItems.reduce((acc: any, item: any) => {
                        let totalUnit = 0;
                        for (let product of item.transactionItem.products) {
                          if (product.productData.uom === item.deliveryType) {
                            totalUnit += product.unitQuantity;
                          }
                        }
                        return acc + item.quantityDelivery * totalUnit;
                      }, 0)}
                    </Text>
                  }
                >
                  <PopoverTableCard
                    data={deliveryItems}
                    structure={{
                      code: {
                        headerTitle: `Informasi Pesanan (${deliveryItems.length})`,
                        render: ({
                          transactionItem: {products},
                          quantityDelivery,
                          deliveryType,
                        }) => {
                          return (
                            <Fragment>
                              {products
                                .filter((item: any) => {
                                  return item.productData.uom === deliveryType;
                                })
                                .map((product: any) => {
                                  return (
                                    <View
                                      style={[styles.dataRow, {width: 300}]}
                                    >
                                      <Text
                                        size="small"
                                        color={BLACK}
                                        style={{
                                          letterSpacing: 1.5,
                                          flex: 7,
                                        }}
                                      >
                                        {product.productData.title}{' '}
                                      </Text>
                                      <Text
                                        size="small"
                                        color={BLACK}
                                        style={{
                                          letterSpacing: 1.5,
                                          flex: 1,
                                        }}
                                      >
                                        -
                                      </Text>
                                      <Text
                                        size="small"
                                        color={BLACK}
                                        style={{
                                          letterSpacing: 1.5,
                                          flex: 3,
                                        }}
                                      >
                                        {product.unitQuantity}
                                        {' x '}
                                        {quantityDelivery}{' '}
                                      </Text>
                                    </View>
                                  );
                                })}
                            </Fragment>
                          );
                        },
                      },
                    }}
                  />
                </Popover>
              </View>
            ),
          },
          szID: {
            alias: 'transaction.user.szID',
            headerTitle: 'Kode Pelanggan',
          },
          storeName: {
            alias: 'transaction.user.storeName',
            headerTitle: 'Nama Toko',
          },
          contact: {
            alias: 'transaction.user.storeTelephone',
            headerTitle: 'Kontak',
          },
          createdAt: {
            alias: 'transaction.createdAt',
            processor: (date) => {
              return new Date(date).toLocaleDateString('id').toString();
            },
            headerTitle: 'Tanggal Order',
          },
          deliveryDate: {
            alias: 'transaction.deliveryDate',
            processor: (date) => {
              return new Date(date).toLocaleDateString('id').toString();
            },
            headerTitle: 'Tanggal Dikirim',
          },
          rejectedNotes: {
            alias: 'assignedOrder.cancelReason',
            headerTitle: 'Alasan Ditolak',
          },
          actions: {
            render: ({transaction, returnedTransactionItems}) => (
              <View style={styles.customField}>
                <Icon
                  size="small"
                  name="description"
                  color={GRAY}
                  hoverColor={PRIMARY}
                  onPress={() =>
                    this.setState({
                      isInvoiceVisible: true,
                      actionData: transaction,
                      returnedData: {
                        id: transaction.id,
                        invoiceNumber: transaction.invoiceNumber,
                        returnedTransactionItems,
                      },
                    })
                  }
                />
              </View>
            ),
            noHeaderName: true,
          },
        }}
        loadMore={({first, skip}) => {
          fetchMoreItems(fetchMore, {
            query: GET_DELIVERY_STATUS,
            variables: {
              ritWhere: this._getQueryWhere('RIT_WHERE'),
              transactionWhere: this._getQueryWhere('TRANSACTION_WHERE'),
              first,
              skip,
            },
            dataKey,
          });
        }}
        onChangeRowsPerPage={(newRowsPerPage) => {
          this.setState({
            rowsPerPage: newRowsPerPage,
            page: 0,
          });
        }}
      />
    );
  };

  _renderTable = () => {
    let {rowsPerPage, page} = this.state;
    let {resetPage, setResetPage} = this.props;
    const dataKey = 'deliveries';
    return (
      <Query<DeliveryListResult, DeliveryListParams>
        query={GET_DELIVERY_STATUS}
        variables={{
          ritWhere: this._getQueryWhere('RIT_WHERE'),
          transactionWhere: this._getQueryWhere('TRANSACTION_WHERE'),
          first: rowsPerPage, //10
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            // let {
            //   deliveries: {ritTransactions, count},
            // } = data;
            let {deliveries} = data;
            let {ritTransactions, count} = deliveries;
            return (
              <View style={styles.bodyWrapper}>
                {this.props.renderSyncModal(() =>
                  refetchItems<DeliveryListResult, DeliveryListParams>(
                    fetchMore,
                    {
                      query: GET_DELIVERY_STATUS,
                      variables: {
                        ritWhere: this._getQueryWhere('RIT_WHERE'),
                        transactionWhere: this._getQueryWhere(
                          'TRANSACTION_WHERE',
                        ),
                        // 59
                        first: rowsPerPage, // 10
                        skip: page * rowsPerPage, // 20
                      },
                      dataKey,
                      rowsPerPage,
                      page,
                    },
                  ),
                )}

                <Table
                  data={ritTransactions || []}
                  dataCount={count || 0}
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    poNumber: {
                      alias: 'transaction.poNumber',
                      headerTitle: 'No. PO',
                    },
                    doNumber: {
                      alias: 'assignedOrder.doNumber',
                      headerTitle: 'No. DO',
                    },
                    invoiceNumber: {
                      alias: 'transaction.invoiceNumber',
                      headerTitle: 'Invoice',
                    },
                    driverCode: {
                      alias: 'rit.driver.driverCode',
                      headerTitle: 'Kode Driver',
                    },
                    driverName: {
                      alias: 'rit.driver.driverName',
                      headerTitle: 'Nama Driver',
                    },
                    totalOrder: {
                      headerTitle: 'Total Pesanan',
                      render: ({assignedOrder: {deliveryItems}}) => (
                        <View style={styles.customField}>
                          <Popover
                            popoverTrigger={
                              <Text
                                size="small"
                                weight="bold"
                                color={NAVY_BLUE}
                                style={{letterSpacing: 1.5}}
                              >
                                {deliveryItems
                                  .map((delivery) => {
                                    return (
                                      delivery.transactionItem.products
                                        .filter(
                                          (product) =>
                                            product.productData.uom ===
                                            delivery.deliveryType,
                                        )
                                        .reduce(
                                          (prev, curr) =>
                                            prev + curr.unitQuantity,
                                          0,
                                        ) * delivery.transactionItem.quantity
                                    );
                                  })
                                  .reduce((prev, curr) => prev + curr, 0)}
                              </Text>
                            }
                          >
                            <PopoverTableCard
                              data={deliveryItems}
                              structure={{
                                code: {
                                  headerTitle: `Informasi Pesanan (${deliveryItems.length})`,
                                  render: ({
                                    transactionItem: {products, quantity},
                                    deliveryType,
                                  }) => {
                                    return (
                                      <Fragment>
                                        {products
                                          .filter((item: any) => {
                                            return (
                                              item.productData.uom ===
                                              deliveryType
                                            );
                                          })
                                          .map((product: any) => {
                                            return (
                                              <View
                                                style={[
                                                  styles.dataRow,
                                                  {width: 300},
                                                ]}
                                              >
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 7,
                                                  }}
                                                >
                                                  {product.productData.title}{' '}
                                                </Text>
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 1,
                                                  }}
                                                >
                                                  -
                                                </Text>
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 3,
                                                  }}
                                                >
                                                  {product.unitQuantity}
                                                  {' x '}
                                                  {quantity}{' '}
                                                </Text>
                                              </View>
                                            );
                                          })}
                                      </Fragment>
                                    );
                                  },
                                },
                              }}
                            />
                          </Popover>
                        </View>
                      ),
                    },
                    szID: {
                      alias: 'transaction.user.szID',
                      headerTitle: 'Kode Pelanggan',
                    },
                    storeName: {
                      alias: 'transaction.user.storeName',
                      headerTitle: 'Nama Toko',
                    },
                    contact: {
                      alias: 'transaction.user.storeTelephone',
                      headerTitle: 'Kontak',
                    },
                    createdAt: {
                      alias: 'transaction.createdAt',
                      processor: (date) => {
                        return new Date(date)
                          .toLocaleDateString('id')
                          .toString();
                      },
                      headerTitle: 'Tanggal Order',
                    },
                    deliveryDate: {
                      alias: 'transaction.deliveryDate',
                      processor: (date) => {
                        return new Date(date)
                          .toLocaleDateString('id')
                          .toString();
                      },
                      headerTitle: 'Tanggal Dikirim',
                    },
                    pendingReason: {
                      alias: 'assignedOrder.pendingReason',
                      headerTitle: 'Alasan Tunda',
                    },
                    rejectedNotes: {
                      alias: 'assignedOrder.cancelReason',
                      headerTitle: 'Alasan Ditolak',
                    },
                    actions: {
                      render: ({
                        transaction,
                        assignedOrder,
                        returnedTransactionItems,
                      }) => (
                        <View style={styles.customField}>
                          <Icon
                            size="small"
                            name="description"
                            color={GRAY}
                            hoverColor={PRIMARY}
                            onPress={() =>
                              this.setState({
                                isInvoiceVisible: true,
                                actionData: {
                                  ...transaction,
                                  ...{
                                    ...assignedOrder,
                                    canceledOrders: [],
                                  },
                                },
                                returnedData: {
                                  id: transaction.id,
                                  invoiceNumber: transaction.invoiceNumber,
                                  returnedTransactionItems,
                                },
                              })
                            }
                          />
                        </View>
                      ),
                      noHeaderName: true,
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_DELIVERY_STATUS,
                      variables: {
                        ritWhere: this._getQueryWhere('RIT_WHERE'),
                        transactionWhere: this._getQueryWhere(
                          'TRANSACTION_WHERE',
                        ),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) => {
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    });
                  }}
                />

                {/* {
                  loading
                  ?
                  this._renderTableContent(
                    ritTransactions,
                    count,
                    resetPage,
                    setResetPage,
                    loading,
                    page,
                    rowsPerPage,
                    fetchMore,
                    dataKey,
                  )
                  :
                  this._renderTableContent(
                    ritTransactions,
                    count,
                    resetPage,
                    setResetPage,
                    loading,
                    page,
                    rowsPerPage,
                    fetchMore,
                    dataKey,
                  )
                } */}

                {this.state.actionData && this.state.returnedData ? (
                  <OrderDetailModal
                    order={this.state.actionData}
                    returnedItem={this.state.returnedData}
                    isVisible={this.state.isInvoiceVisible}
                    onClose={() => this.setState({isInvoiceVisible: false})}
                  />
                ) : null}
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  dataRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 15,
  },
  customField: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(RejectedOrderList);
