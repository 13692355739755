import React, {Component, ChangeEvent, ReactNode, ReactElement} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from 'react-native';
import {compose, graphql, DataValue} from 'react-apollo';
import classNames from 'classnames';

import {
  Checkbox,
  withStyles,
  WithStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@material-ui/core';
import {getSorting} from '../../helpers';
import TablePaginationAction from './TablePagination';
import {Text, Icon, Loading} from '../../core-ui';
import {NoDataPlaceholder} from '../';
import {
  PRIMARY,
  WHITE,
  BLACK,
  GRAY,
  BACKDROP_TRANSPARENT,
} from '../../constants/colors';

import {GET_SEARCH_STATE} from '../../graphql/queries/';
import {SearchState} from '../../graphql/localState';

export const DEFAULT_ROWS_PER_PAGE = 5;

export type RowsPerPage = 5 | 10 | 25 | 50;

type SearchStateProps = {searchStateQuery: DataValue<SearchState, {}>};

type StructureStyle = Array<
  'grey' | 'alignCenter' | 'narrowNumberColumn' | 'alignRight' | ObjectKey
>;

type StructureElement<T = ObjectKey> = {
  alias?: string;
  processor?: (data: string) => string;
  render?: (data: T, index: number) => ReactNode;
  isOrder?: boolean;
  style?: StructureStyle;
  headerTitle?: string;
  noHeaderName?: boolean;
  headerCenter?: boolean;
  colWidth?: number;
  letterSpacing?: number;
  textSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
};

export type TableStructure<T = ObjectKey> = {
  [key: string]: StructureElement<T>;
};

export type LoadMoreParams = {
  skip: number;
  first: number;
  searchInput?: string;
};

type State = {
  orderBy: Optional<string>;
  activeOrder: number;
  isDescending: boolean;
};

type OwnProps<T = ObjectKey> = {
  buttonText?: string;
  data: Array<T>;
  dataCount: number;
  isLoading?: boolean;
  noDataPlaceholder?: string;
  resetPage?: boolean;
  rowsPerPage: RowsPerPage;
  showCheckboxes?: boolean;
  hideNumber?: boolean;
  selectedItems?: Array<T>;
  onSelectItem?: (selectedItems: Array<T>) => void;
  onSelectAllItem?: () => void;
  selectLimit?: boolean;
  structure: TableStructure<T>;
  loadMore: (params: LoadMoreParams) => void;
  multiSelectAction?: () => void;
  onChangeRowsPerPage: (newRowsPerPage: RowsPerPage) => void;
  setResetPage?: (isReset: boolean) => void;
  showFooter?: boolean;
  narrowNumberColumn?: boolean;
  extraStyles?: ObjectKey<string>;
  page: number;
  onChangePage: (nextPage: number) => void;
};

type Props<T = ObjectKey> = OwnProps<T> &
  WithStyles<typeof styles> &
  SearchStateProps;

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export class CustomizedTable<T = ObjectKey> extends Component<Props<T>, State> {
  state: State = {
    orderBy: null,
    activeOrder: 0,
    isDescending: false,
  };

  componentDidUpdate() {
    let {resetPage, isLoading, setResetPage} = this.props;
    if (resetPage && isLoading) {
      // hacky way to reset page to 0 on search due to pagination
      setResetPage && setResetPage(false);
      this.props.onChangePage(0);
    }
  }

  render() {
    let {
      isLoading,
      noDataPlaceholder = 'Tidak ada data',
      searchStateQuery: {searchState},
      data,
      selectedItems,
      showFooter = true,
    } = this.props;
    let loading = isLoading != null ? isLoading : true;

    if (!data && !Array.isArray(data) && loading) {
      return <Loading />;
    }
    if ((data && data.length < 1) || !data) {
      if (searchState && searchState.searchedString.length > 0) {
        let notFoundSearch = `'${searchState.searchedString}' tidak ditemukan`;
        return <NoDataPlaceholder text={notFoundSearch} />;
      }
      return <NoDataPlaceholder text={noDataPlaceholder} />;
    }
    return (
      <Paper square={true} elevation={0} style={styles.root}>
        <Paper elevation={0} style={styles.bodyRoot}>
          <Table>
            {!loading && this._renderTableBody()}
            {selectedItems && selectedItems.length > 0
              ? this._renderTableHead(selectedItems)
              : this._renderTableHead([])}
          </Table>
          {loading && (
            <View style={nativeStyles.emptyContainer}>
              <Loading />
            </View>
          )}
        </Paper>
        {showFooter && this._renderPagination()}
      </Paper>
    );
  }

  _styleProcessor = (inputStyles?: StructureStyle) => {
    let {classes} = this.props;
    if (inputStyles) {
      let stylesArr = inputStyles.map((style) => {
        if (typeof style === 'string') {
          return classes[style];
        } else {
          // NOTE: to handle parent style inject
          // but still not working
          return StyleSheet.flatten(style);
        }
      });
      return classNames(...stylesArr);
    }
    return undefined;
  };

  _renderCheckboxAll = (selectedCount: number) => {
    let {data, classes} = this.props;
    let rowCount = data.length;
    return (
      <Checkbox
        indeterminate={selectedCount > 0 && selectedCount <= rowCount}
        indeterminateIcon={
          <Icon name="indeterminate_check_box" color={PRIMARY} size="small" />
        }
        checked={selectedCount === rowCount}
        onChange={(event) => this._handleSelectAll(event, selectedCount)}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
      />
    );
  };

  _renderCheckbox = (row: T) => {
    let {classes, selectedItems, selectLimit} = this.props;
    let id = 'id' as keyof T;
    let isSelected = (selectedId: string) => {
      return (
        selectedItems &&
        selectedItems.some((item) => {
          return String(item[id]) === selectedId;
        })
      );
    };
    return (
      <Checkbox
        checked={isSelected(String(row[id]))}
        onChange={(_) => this._handleSelected(row)}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checked,
        }}
        disabled={!isSelected(String(row[id])) && selectLimit}
      />
    );
  };

  _handleOrderPressed(orderIndex: number, orderBy: string) {
    let {activeOrder, isDescending} = this.state;
    this.setState({
      orderBy,
      activeOrder: orderIndex,
      isDescending: activeOrder === orderIndex ? !isDescending : false,
    });
  }

  _renderTableHead = (selectedArray: Array<T>) => {
    let {activeOrder, isDescending} = this.state;
    let {
      structure,
      classes,
      showCheckboxes,
      narrowNumberColumn,
      hideNumber,
    } = this.props;
    let cells = Object.keys(structure);
    let transitionTiming = isDescending ? 'ease-out' : 'ease';
    let headerContent = cells.map((headerName, index) => {
      let {
        style,
        noHeaderName,
        headerTitle,
        alias,
        isOrder,
        headerCenter,
      } = structure[headerName];
      let headerStyle: StyleProp<ViewStyle> = headerCenter
        ? {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {
            flexDirection: 'row',
            alignItems: 'center',
          };
      let degreeRotate =
        isDescending && index === activeOrder ? '180deg' : '0deg';
      let headerChild = (
        <View style={headerStyle}>
          <Text
            size="xsmall"
            weight="bold"
            color={isOrder && index === activeOrder ? BLACK : undefined}
          >
            {headerTitle ? headerTitle.toUpperCase() : headerName.toUpperCase()}
          </Text>
          {isOrder && (
            <Icon
              size="small"
              color={index === activeOrder ? BLACK : undefined}
              containerStyle={
                ({
                  transform: [{rotate: degreeRotate}],
                  transitionDuration: '0.5s',
                  transitionProperty: 'transform',
                  transitionTimingFunction: transitionTiming,
                } as unknown) as ViewStyle // NOTE: this is necessary as transitions are part of CSS styles, not ViewStyle
              }
              name="arrow_drop_down"
              onPress={() =>
                this._handleOrderPressed(
                  index,
                  isOrder && alias ? alias : headerName,
                )
              }
            />
          )}
        </View>
      );
      return (
        <TableCell
          className={classNames(classes.cellRoot, this._styleProcessor(style))}
          key={index}
        >
          {noHeaderName ? null : isOrder ? (
            <TouchableOpacity
              onPress={() =>
                this._handleOrderPressed(index, alias ? alias : headerName)
              }
            >
              {headerChild}
            </TouchableOpacity>
          ) : (
            headerChild
          )}
        </TableCell>
      );
    });
    return (
      <TableHead className={classes.headerRow}>
        <TableRow>
          {showCheckboxes ? (
            <TableCell className={classes.cellRoot}>
              {this._renderCheckboxAll(selectedArray.length)}
            </TableCell>
          ) : null}
          {hideNumber ? null : (
            <TableCell
              className={classNames(
                classes.cellRoot,
                this._styleProcessor([
                  'grey',
                  narrowNumberColumn ? 'narrowNumberColumn' : {},
                  'alignCenter',
                ]),
              )}
            >
              <Text size="xsmall" weight="bold">
                NO
              </Text>
            </TableCell>
          )}
          {headerContent}
        </TableRow>
      </TableHead>
    );
  };

  _renderTableRow = (currentRow: T, parentIndex: number) => {
    let {
      structure,
      classes,
      showCheckboxes,
      narrowNumberColumn,
      hideNumber,
      extraStyles = {},
    } = this.props;
    let cells = Object.keys(structure);

    return (
      <TableRow
        className={`${classes.row} ${extraStyles.row}`}
        key={parentIndex}
      >
        {showCheckboxes ? (
          <TableCell className={classes.cellRoot}>
            {this._renderCheckbox(currentRow)}
          </TableCell>
        ) : null}
        {hideNumber ? null : (
          <TableCell
            className={classNames(
              classes.cellRoot,
              this._styleProcessor([
                narrowNumberColumn ? 'narrowNumberColumn' : {},
                'alignCenter',
              ]),
            )}
          >
            <Text size="small">{parentIndex + 1}</Text>
          </TableCell>
        )}
        {cells.map((headerName, index) => {
          let {
            style,
            render,
            alias,
            processor,
            colWidth,
            textSize,
            letterSpacing,
          } = structure[headerName];
          let dataRow = alias
            ? this._aliasResolver(currentRow, alias)
            : currentRow[headerName as keyof T];
          return (
            <TableCell
              className={classNames(
                classes.body,
                extraStyles.cell,
                classes.cellRoot,
                this._styleProcessor(style),
              )}
              key={index}
            >
              {render ? (
                render(currentRow, parentIndex)
              ) : (
                <View style={colWidth ? {width: colWidth} : {}}>
                  <Text
                    size={textSize ? textSize : 'small'}
                    style={
                      letterSpacing ? {letterSpacing} : nativeStyles.cellText
                    }
                  >
                    {dataRow != null && dataRow !== ''
                      ? processor
                        ? processor(String(dataRow))
                        : String(dataRow).toUpperCase()
                      : '-'}
                  </Text>
                </View>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  _getSortedData() {
    let {data, structure, page} = this.props;
    let {isDescending, orderBy} = this.state;
    let {rowsPerPage} = this.props;
    let fields = Object.keys(structure);
    let beginIndex = page * rowsPerPage;
    let endIndex = page * rowsPerPage + rowsPerPage;
    let initialSortedField = '' as keyof T;
    fields.forEach((field) => {
      let {alias, isOrder} = structure[field];
      if (isOrder) {
        initialSortedField = (alias ? alias : field) as keyof T;
      }
    });
    let orderField = orderBy || initialSortedField;
    let dataToDisplay = data.slice(beginIndex, endIndex);
    return {
      sortedData: dataToDisplay.sort(getSorting(isDescending, orderField)),
      beginIndex,
      endIndex,
    };
  }

  _renderTableBody = () => {
    let {sortedData, beginIndex} = this._getSortedData();
    return (
      <TableBody style={styles.body}>
        {sortedData.map((currentRow, parentIndex) => {
          return this._renderTableRow(currentRow, parentIndex + beginIndex);
        })}
      </TableBody>
    );
  };

  _renderPagination = () => {
    let {classes, dataCount, rowsPerPage, page} = this.props;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              colSpan={8}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onChangePage={this._handleChangePage}
              onChangeRowsPerPage={this._handleChangeRowsPerPage}
              ActionsComponent={TablePaginationAction}
              labelDisplayedRows={({to, count}) => (
                <Text size="small">
                  {to} dari {count}
                </Text>
              )}
              labelRowsPerPage={<Text size="small">Baris per halaman</Text>}
              classes={{
                root: classes.caption,
                select: classes.select,
                caption: classes.caption,
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  _handleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    selectedCount: number,
  ) => {
    let {onSelectAllItem} = this.props;
    if (event.target.checked && selectedCount === 0) {
      onSelectAllItem && onSelectAllItem();
      return;
    }
    this._clearSelected();
  };

  _handleSelected = (selectedItem: T) => {
    let {selectedItems, onSelectItem} = this.props;
    let id = 'id' as keyof T;
    if (selectedItems) {
      let isSelectedIndex = selectedItems.some((item) => {
        return String(item[id]) === String(selectedItem[id]);
      });
      let newSelectedItem = isSelectedIndex
        ? selectedItems.filter((item) => item[id] !== selectedItem[id])
        : [...selectedItems, selectedItem];
      onSelectItem && onSelectItem(newSelectedItem);
    }
  };

  _clearSelected = () => {
    let {onSelectItem} = this.props;
    onSelectItem && onSelectItem([]);
  };

  _aliasResolver = (
    data: ObjectKey,
    alias: string,
    index: number = 0,
  ): string => {
    let keys = alias.split('.');
    let temp = data ? data[keys[index]] : '';
    if (typeof temp === 'string') {
      return temp;
    } else {
      return this._aliasResolver(temp, alias, ++index);
    }
  };

  _handleChangePage = (_: CustomMouseEvent, nextPage: number) => {
    let {
      data,
      loadMore,
      dataCount,
      searchStateQuery: {searchState},
      rowsPerPage,
      page,
    } = this.props;
    let isNextPagePressed = nextPage === page + 1;
    let totalPage = Math.ceil(dataCount / rowsPerPage);
    let nextPageIsLastPage = nextPage + 1 === totalPage;
    if (nextPageIsLastPage && data.length < dataCount) {
      loadMore({
        skip: data.length,
        first: dataCount - data.length,
        searchInput: searchState && searchState.searchedString,
      });
    } else if (
      !nextPageIsLastPage &&
      isNextPagePressed &&
      data.length < rowsPerPage * (nextPage + 1)
    ) {
      loadMore({
        skip: data.length,
        first: rowsPerPage,
        searchInput: searchState && searchState.searchedString,
      });
    }
    this.props.onChangePage(nextPage);
  };

  _handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    let {rowsPerPage} = this.props;
    let newRowsPerPage = Number(event.target.value) as RowsPerPage;
    if (newRowsPerPage !== rowsPerPage) {
      this.props.onChangeRowsPerPage(newRowsPerPage);
    }
  };
}

const nativeStyles = StyleSheet.create({
  checkboxContainer: {flexDirection: 'row'},
  checkboxAll: {paddingHorizontal: 20},
  checkboxSelection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 10,
  },
  checkboxSelectionText: {alignSelf: 'center'},
  cellText: {letterSpacing: 1.5},
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 150,
  },
});

const styles = createStyles({
  root: {backgroundColor: 'transparent'},
  cellRoot: {
    padding: '4px 18px 4px 16px',
  },
  head: {
    maxHeight: 17,
    color: BACKDROP_TRANSPARENT,
    letterSpacing: 2,
    border: 'none',
    padding: '0px 20px 0px 20px',
  },
  bodyRoot: {
    overflowX: 'auto',
    backgroundColor: 'transparent',
    paddingRight: 1, // NOTE: this is necessary to counter-balance the right border of last-child
  },
  body: {
    '&:lastChild': {
      borderRight: `1px solid ${GRAY}`,
    },
    backgroundColor: WHITE,
  },
  checkboxRoot: {
    '&$checked': {
      color: PRIMARY,
    },
  },
  checked: {},
  alignCenter: {
    textAlign: 'center',
  },
  narrowNumberColumn: {
    width: 50,
  },
  alignRight: {
    textAlign: 'right',
  },
  grey: {
    color: BACKDROP_TRANSPARENT,
  },
  caption: {
    borderStyle: 'none',
    fontSize: 15,
    color: BACKDROP_TRANSPARENT,
    fontFamily: 'Rubik-Regular',
  },
  select: {
    padding: '7px 20px 7px 0px',
  },
  row: {
    height: 60,
    backgroundColor: WHITE,
    border: `1px solid ${GRAY}`,
  },
  headerRow: {
    color: BACKDROP_TRANSPARENT,
  },
});

export default compose(
  graphql<OwnProps, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(withStyles(styles)(CustomizedTable)) as <T = ObjectKey>(
  props: OwnProps<T>,
) => ReactElement;
