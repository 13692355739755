import React, {Fragment} from 'react';
import {Mutation, compose} from 'react-apollo';
import {StyleSheet, View} from 'react-native';

import {Table} from '../../../../components';
import {BLACK, GRAY, WHITE} from '../../../../constants/colors';
import {Button, Icon, Modal, Text} from '../../../../core-ui';
import {PurchaseOrder} from '../../../../fixtures/orderListData';
import {
  CREATE_DRIVER_RIT,
  CUDDriverRITResult,
  CreateDriverRITArgs,
  EDIT_DRIVER_RIT,
  EditDriverRITArgs,
} from '../../../../graphql/mutations';
import {TotalOrder} from '../../../../graphql/queries';
import {shiftIndexDown, shiftIndexUp} from '../../../../helpers';
import withToast, {ToastContextProps} from '../../../../helpers/withToast';
import {TotalRemainingOrderRefetch} from '../../AssignDriverListScene';

type OwnProps = {
  selectedPurchases: Array<
    PurchaseOrder & {
      totalOrder: TotalOrder[];
    }
  >;
  editRITID?: string;
  driverNIK: string;
  closeAssignModal: () => void;
  totalRefetchQuery: TotalRemainingOrderRefetch;
};

type State = {
  page: number;
  resetPage: boolean;
  selectedSubDistrict: Nullable<string>;
  purchasesOrder: Array<
    PurchaseOrder & {
      totalOrder: TotalOrder[];
    }
  >;
  isShowConfirmModal: boolean;
};

type Props = OwnProps & ToastContextProps;

export class RouteOrder extends React.Component<Props, State> {
  state: State = {
    page: 0,
    resetPage: false,
    selectedSubDistrict: null,
    purchasesOrder: this.props.selectedPurchases,
    isShowConfirmModal: false,
  };

  render() {
    let {purchasesOrder} = this.state;
    return (
      <Fragment>
        {this._renderConfirmModal()}
        <View style={styles.bodyWrapper}>
          <Text weight="bold">Rute Destinasi Pengiriman</Text>
          <Text size="small" color={GRAY}>
            Tentukan urutan rute pengiriman barang
          </Text>
        </View>
        <View style={styles.body}>{this._renderTable()}</View>
        <View style={styles.buttonContainer}>
          <Button
            onPress={this._showConfirmationModal}
            disabled={purchasesOrder.length === 0}
          >
            Assign
          </Button>
        </View>
      </Fragment>
    );
  }

  _showConfirmationModal = () => {
    this.setState({
      isShowConfirmModal: true,
    });
  };

  _onAssignError = () => {
    let {openToast, editRITID, closeAssignModal} = this.props;
    this._closeModal();
    closeAssignModal();
    editRITID
      ? openToast('fail', 'Ritase gagal diubah.')
      : openToast('fail', 'Ritase gagal dibuat.');
  };

  _onAssignSuccess = () => {
    let {
      openToast,
      editRITID,
      closeAssignModal,
      totalRefetchQuery,
    } = this.props;
    totalRefetchQuery && totalRefetchQuery();
    this._closeModal();
    closeAssignModal();
    editRITID
      ? openToast('success', 'Ritase berhasil diubah.')
      : openToast('success', 'Ritase berhasil dibuat.');
  };

  _renderConfirmModal = () => {
    let {isShowConfirmModal, purchasesOrder} = this.state;
    let {editRITID, driverNIK, selectedPurchases} = this.props;

    return (
      <Mutation<CUDDriverRITResult, EditDriverRITArgs | CreateDriverRITArgs>
        mutation={editRITID ? EDIT_DRIVER_RIT : CREATE_DRIVER_RIT}
        onCompleted={this._onAssignSuccess}
        onError={this._onAssignError}
      >
        {(assignProvider, {loading}) => {
          return (
            <Modal
              isVisible={isShowConfirmModal}
              title={'Assign Driver'}
              onClose={this._closeModal}
              isLoading={loading}
              description={
                'Driver akan menerima pesanan dan mengirimkan barang ke alamat yang tertera. Apakah Anda yakin?'
              }
              children={
                <View style={styles.modalView}>
                  <View style={styles.button}>
                    <Button
                      color="secondary"
                      inverted={true}
                      text="Batal"
                      onPress={this._closeModal}
                      loadingColor={WHITE}
                    />
                  </View>
                  <View style={styles.button}>
                    <Button
                      color="primary"
                      text="Assign"
                      isLoading={loading}
                      onPress={() => {
                        const mixOrder = purchasesOrder
                          .map((data) => {
                            const orderUoms = data.totalOrder.map(
                              (order) => order.uom,
                            );

                            if (
                              orderUoms.every((uom) => orderUoms[0] === uom)
                            ) {
                              return orderUoms[0].toLowerCase();
                            }

                            return 'box dan galon';
                          })
                          .join('-');

                        driverNIK = driverNIK + '-' + mixOrder;

                        let arrangedPONumbers = purchasesOrder.map(
                          ({poNumber}) => {
                            return poNumber;
                          },
                        );
                        let transactionsOrder = selectedPurchases.map(
                          ({poNumber}) => {
                            return {poNumber};
                          },
                        );
                        editRITID = editRITID
                          ? editRITID + '-' + mixOrder
                          : editRITID;

                        let assignVariable:
                          | EditDriverRITArgs
                          | CreateDriverRITArgs = editRITID
                          ? {
                              where: {id: editRITID},
                              transactions: transactionsOrder,
                              transactionArrangement: arrangedPONumbers,
                            }
                          : {
                              driverNIK,
                              transactions: transactionsOrder,
                              transactionArrangement: arrangedPONumbers,
                            };
                        assignProvider({
                          variables: assignVariable,
                        });
                      }}
                      loadingColor={WHITE}
                    />
                  </View>
                </View>
              }
            />
          );
        }}
      </Mutation>
    );
  };

  _closeModal = () => {
    this.setState({
      isShowConfirmModal: false,
    });
  };

  _renderTable = () => {
    let {resetPage, page, purchasesOrder} = this.state;

    return (
      <View style={styles.bodyWrapper}>
        <Table
          data={purchasesOrder || []}
          dataCount={purchasesOrder.length || 0}
          resetPage={resetPage}
          setResetPage={(reset) => this.setState({resetPage: reset})}
          isLoading={false}
          page={page}
          showFooter={false}
          onChangePage={(nextPage) => this.setState({page: nextPage})}
          rowsPerPage={10}
          structure={{
            customerCode: {
              headerTitle: 'Kode Pelanggan',
              colWidth: 120,
            },

            storeName: {
              headerTitle: 'Nama Toko',
              colWidth: 150,
            },

            storeAddress: {
              headerTitle: 'Alamat Toko',
              colWidth: 150,
            },
            subDistrict: {
              headerTitle: 'Kelurahan',
              colWidth: 120,
            },
            action: {
              noHeaderName: true,
              render: (_, index) => {
                return (
                  <View style={{}}>
                    <Icon
                      size="xsmall"
                      name="keyboard_arrow_up"
                      disabled={index === 0}
                      color={index === 0 ? GRAY : BLACK}
                      onPress={() => {
                        this.setState({
                          purchasesOrder: shiftIndexUp(purchasesOrder, index),
                        });
                      }}
                    ></Icon>
                    <Icon
                      size="xsmall"
                      name="keyboard_arrow_down"
                      disabled={index === purchasesOrder.length - 1}
                      color={index === purchasesOrder.length - 1 ? GRAY : BLACK}
                      onPress={() => {
                        this.setState({
                          purchasesOrder: shiftIndexDown(purchasesOrder, index),
                        });
                      }}
                    ></Icon>
                  </View>
                );
              },
            },
          }}
          loadMore={() => {}}
          onChangeRowsPerPage={() => {}}
        />
      </View>
    );
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  body: {
    flex: 1,
    marginTop: 10,
  },
  filters: {
    alignItems: 'flex-start',
    paddingTop: 20,
    zIndex: 3,
  },
  button: {
    marginHorizontal: 10,
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    paddingTop: 10,
    alignItems: 'flex-end',
  },
  modalView: {flexDirection: 'row', justifyContent: 'flex-end'},
});

export default compose(withToast)(RouteOrder);
