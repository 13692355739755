import React, {Fragment} from 'react';
import {Mutation, OperationVariables, compose, graphql} from 'react-apollo';
import {StyleSheet, View} from 'react-native';
import {RouteComponentProps} from 'react-router';
import {
  DepotPicker,
  ErrorScreen,
  Query,
  ResetFilterButton,
  RowsPerPage,
  Table,
} from '../../components';
import {
  BLACK,
  DARK_GRAY,
  GRAY,
  GREEN,
  PRIMARY,
  WHITE,
} from '../../constants/colors';
import {Button, Icon, Modal, Text, TextField} from '../../core-ui';
import {SearchState} from '../../graphql/localState';
import {ACTIVATE_DRIVER} from '../../graphql/mutations';
import {
  DriverListParams,
  DriverListResult,
  GET_DRIVERS,
  GET_SEARCH_STATE,
  SearchStateProps,
} from '../../graphql/queries';
import {
  asyncStorage,
  convertDepoToPostgresqlIDpro,
  fetchMoreItems,
  formatNumberDate,
  refetchItems,
} from '../../helpers';
import withToast, {ToastContextProps} from '../../helpers/withToast';

type State = {
  isEditVisible: boolean;
  page: number;
  rowsPerPage: RowsPerPage;
  selectedNIK: string;
  selectedPassword: string;
  resetPage: boolean;
  selectedDepo: Nullable<string>;
  nameUserDepo: string;
  idPostresqlDepo: string;
};

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = SceneProps &
  RouteComponentProps &
  ToastContextProps &
  SearchStateProps & {role: string};

export class DriverList extends React.Component<Props, State> {
  state: State = {
    isEditVisible: false,
    page: 0,
    rowsPerPage: 10,
    selectedNIK: '',
    selectedPassword: '',
    resetPage: false,
    selectedDepo: null,
    nameUserDepo: '',
    idPostresqlDepo: '',
  };

  async componentDidMount() {
    let nameUserDepo = (await asyncStorage.getName()) || '';
    this.setState({nameUserDepo});

    try {
      this.setState({
        idPostresqlDepo: convertDepoToPostgresqlIDpro(nameUserDepo),
      });
    } catch (error) {
      this.setState({idPostresqlDepo: ''});
    }

    /*
      pastikan untuk di function helper convertDepoToPostgresqlID di ganti staging atau prodction sesuai dengan kebutuhan
    */
  }

  render() {
    return (
      <Fragment>
        {this._renderFilters()}
        {this._renderTable()}
      </Fragment>
    );
  }

  _renderModal = (refetchFn: () => void) => {
    let {isEditVisible, selectedPassword} = this.state;
    return (
      <Mutation<DriverListResult, OperationVariables>
        mutation={ACTIVATE_DRIVER}
        onCompleted={() => {
          let {openToast} = this.props;
          openToast && selectedPassword !== ''
            ? openToast('success', 'Akun driver berhasil diaktifkan')
            : openToast('success', 'Akun driver telah berhasil dinonaktifkan');
          refetchFn();
          this.setState({isEditVisible: false});
        }}
        onError={this._onActivateError}
      >
        {(activateProvider, {loading}) => {
          return (
            <Modal
              isVisible={isEditVisible}
              title="Tambah Akun Driver"
              buttonText="Tambah"
              isLoading={loading}
              description={
                <View>
                  <Text size="small" color={DARK_GRAY}>
                    Masukkan kata sandi untuk mengaktifkan akun driver
                    (kosongkan untuk menonaktifkan).{' '}
                  </Text>
                  <View style={styles.rootContentModal}>
                    <View style={styles.leftContentModal}>
                      <Text size="small">NIK</Text>
                      <View style={{marginTop: 20}}>
                        <Text size="small">Kata Sandi</Text>
                      </View>
                    </View>
                    <View style={styles.rightContentModal}>
                      <Text size="small">{this.state.selectedNIK}</Text>
                      <View style={{marginTop: 20}}>
                        <TextField
                          stretch
                          value={selectedPassword}
                          onChangeText={(text: string) => {
                            this.setState({selectedPassword: text});
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              }
              children={
                <View style={styles.modalView}>
                  <Button
                    style={{marginRight: 15}}
                    inverted
                    color="secondary"
                    text="Batal"
                    onPress={this._closeAdd}
                    loadingColor={WHITE}
                  />
                  <Button
                    color="primary"
                    text="Tambah"
                    onPress={() => {
                      let {selectedNIK} = this.state;
                      selectedNIK &&
                        activateProvider({
                          variables: {
                            driverNIK: selectedNIK,
                            newPassword: selectedPassword,
                          },
                        });
                    }}
                    loadingColor={WHITE}
                  />
                </View>
              }
              onClose={this._closeAdd}
            />
          );
        }}
      </Mutation>
    );
  };

  _onActivateError = () => {
    let {openToast} = this.props;
    openToast && openToast('fail', 'Driver gagal diaktifkan.');
  };

  _getQueryWhere = () => {
    let {
      searchStateQuery: {searchState},
    } = this.props;

    let {selectedDepo, idPostresqlDepo} = this.state;
    // console.log(selectedDepo, idPostresqlDepo);

    // if (searchState) {
    //   let searchText = searchState.searchedString.toLowerCase();
    //   return {
    //     OR: [
    //       {driverCode_contains: searchText},
    //       {driverNIK_contains: searchText},
    //       {driverName_contains: searchText},
    //     ],
    //   };
    // }
    // return {
    //   // ...(selectedDepo || idPostresqlDepo ? {depot: {id: selectedDepo || idPostresqlDepo}} : {})
    //   ...(selectedDepo ? {depot: {id: selectedDepo}} : {})
    // };

    return {
      ...(selectedDepo || idPostresqlDepo
        ? {depot: {id: selectedDepo || idPostresqlDepo}}
        : {}),
      OR: [
        {
          driverCode_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverNIK_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverName_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
      ],
      // driverName_contains: searchState
      //   ? searchState.searchedString.toLowerCase()
      //   : ''
    };
  };

  _closeAdd = () => this.setState({isEditVisible: false});

  _renderTable = () => {
    let {rowsPerPage, page} = this.state;
    let {resetPage, setResetPage} = this.props;
    const dataKey = 'drivers';
    return (
      <Query<DriverListResult, DriverListParams>
        query={GET_DRIVERS}
        variables={{
          where: this._getQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          // console.log(data);
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            data.drivers.forEach((item) => {
              item.birthDate = formatNumberDate(
                new Date(item.birthDate),
                false,
                false,
              );
              item.joinDate = formatNumberDate(
                new Date(item.joinDate),
                false,
                false,
              );
              item.resignDate = formatNumberDate(
                new Date(item.resignDate),
                false,
                false,
              );
            });
            return (
              <View style={styles.bodyWrapper}>
                {this.props.renderSyncModal(() =>
                  refetchItems<DriverListResult, DriverListParams>(fetchMore, {
                    query: GET_DRIVERS,
                    variables: {
                      where: this._getQueryWhere(),
                      first: rowsPerPage,
                      skip: page * rowsPerPage,
                    },
                    dataKey,
                    rowsPerPage,
                    page,
                  }),
                )}
                {this._renderModal(() =>
                  refetchItems<DriverListResult, DriverListParams>(fetchMore, {
                    query: GET_DRIVERS,
                    variables: {
                      where: this._getQueryWhere(),
                      first: rowsPerPage,
                      skip: page * rowsPerPage,
                    },
                    dataKey,
                    rowsPerPage,
                    page,
                  }),
                )}
                <Table
                  data={data.drivers}
                  dataCount={data.count}
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    depot: {headerTitle: 'Depo', alias: 'depot.title'},
                    driverCode: {headerTitle: 'Kode Driver'},
                    driverName: {headerTitle: 'Nama Driver'},
                    driverNIK: {headerTitle: 'NIK'},
                    displayPassword: {
                      processor: (normalCase) => normalCase,
                      headerTitle: 'Password',
                    },
                    telephone: {headerTitle: 'Nomor Telepon'},
                    gender: {headerTitle: 'Jenis Kelamin'},
                    birthDate: {
                      headerTitle: 'Tanggal Lahir',
                    },
                    joinDate: {headerTitle: 'Tanggal Join'},
                    resignDate: {headerTitle: 'Tanggal Stop'},
                    actions: {
                      render: ({driverNIK, displayPassword}) => (
                        <View style={styles.actionTableWrapper}>
                          {displayPassword ? (
                            <Icon
                              size="small"
                              name="person"
                              color={GREEN}
                              hoverColor={PRIMARY}
                              onPress={() =>
                                this.setState({
                                  isEditVisible: true,
                                  selectedNIK: driverNIK,
                                  selectedPassword: displayPassword
                                    ? displayPassword
                                    : '',
                                })
                              }
                            />
                          ) : (
                            <Icon
                              size="small"
                              name="person_add"
                              color={GRAY}
                              hoverColor={PRIMARY}
                              onPress={() =>
                                this.setState({
                                  isEditVisible: true,
                                  selectedNIK: driverNIK,
                                  selectedPassword: displayPassword
                                    ? displayPassword
                                    : '',
                                })
                              }
                            />
                          )}
                        </View>
                      ),
                      noHeaderName: true,
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_DRIVERS,
                      variables: {
                        where: this._getQueryWhere(),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _renderFilters = () => {
    let {selectedDepo} = this.state;
    return (
      this.props.role === 'SUPER_ADMIN' && (
        <View style={styles.filters}>
          <Fragment>
            <View style={styles.filterRow}>
              <DepotPicker
                isFilter
                style={styles.filterMargin}
                selectedOption={selectedDepo}
                onChange={(selected) => {
                  // console.log(selected);
                  this.setState({
                    selectedDepo: selected ? selected.value : selected,
                    resetPage: true,
                  });
                }}
                hidden={this.props.role !== 'SUPER_ADMIN'}
              />
            </View>
          </Fragment>
          <ResetFilterButton onPress={this._clearFilter} />
        </View>
      )
    );
  };

  _clearFilter = () => {
    this.setState({
      selectedDepo: null,
    });
  };
}

const filterStyle = StyleSheet.create({
  filterButton: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
});

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rootContentModal: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 40,
  },
  leftContentModal: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 4,
  },
  rightContentModal: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 8,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    alignItems: 'flex-start',
    paddingTop: 10,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterMargin: {
    marginRight: 15,
  },
  modalView: {flexDirection: 'row', justifyContent: 'flex-end'},
});

export default compose(
  withToast,
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(DriverList);
