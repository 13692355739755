import React, {Fragment} from 'react';
import {compose, graphql} from 'react-apollo';
import {StyleSheet, View} from 'react-native';
import {
  ErrorScreen,
  OrderDetailModal,
  Popover,
  PopoverTableCard,
  Query,
  RowsPerPage,
  Table,
} from '../../components';
import {BLACK, GRAY, NAVY_BLUE, PRIMARY} from '../../constants/colors';
import {Icon, Text} from '../../core-ui';
import {SearchState} from '../../graphql/localState';
import {
  AssignedOrder,
  DeliveryListParams,
  DeliveryListResult,
  GET_DELIVERY_STATUS,
  GET_SEARCH_STATE,
  Order,
  QueryWhere,
  ReturnedItem,
  SearchStateProps,
} from '../../graphql/queries';
import {fetchMoreItems, refetchItems} from '../../helpers';

type State = {
  page: number;
  rowsPerPage: RowsPerPage;
  isInvoiceVisible: boolean;
  actionData: Nullable<Order & AssignedOrder>;
  returnedData: Nullable<ReturnedItem>;
};

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = SearchStateProps & SceneProps;

export class DeliveredOrderList extends React.Component<Props, State> {
  state: State = {
    page: 0,
    rowsPerPage: 10,
    isInvoiceVisible: false,
    actionData: null,
    returnedData: null,
  };

  render() {
    return <Fragment>{this._renderTable()}</Fragment>;
  }

  _getQueryWhere = (type: QueryWhere) => {
    let {
      searchStateQuery: {searchState},
    } = this.props;
    if (searchState) {
      let searchText = searchState.searchedString.toLowerCase();
      if (type === 'RIT_WHERE') {
        return {
          AND: [
            {
              assignedOrder_some: {
                status: 'COMPLETED',
              },
            },
            {
              OR: [
                {
                  assignedOrder_some:
                    searchText === ''
                      ? {
                          doNumber: null,
                        }
                      : {
                          doNumber_contains: searchText,
                        },
                },
                {
                  driver: {
                    OR: [
                      {driverCode_contains: searchText},
                      {driverName_contains: searchText},
                    ],
                  },
                },
              ],
            },
          ],
        };
      } else if (type === 'TRANSACTION_WHERE') {
        return {
          OR: [
            {poNumber_contains: searchText},
            {soNumber_contains: searchText},
            {invoiceNumber_contains: searchText},
            {
              user: {
                OR: [
                  searchText === ''
                    ? {szID: null}
                    : {szID_contains: searchText},
                  {storeName_contains: searchText},
                  {storeTelephone_contains: searchText},
                ],
              },
            },
          ],
        };
      }
    }
    return {};
  };

  _renderTable = () => {
    let {rowsPerPage, page} = this.state;
    let {resetPage, setResetPage} = this.props;
    const dataKey = 'deliveries';
    return (
      <Query<DeliveryListResult, DeliveryListParams>
        query={GET_DELIVERY_STATUS}
        variables={{
          ritWhere: this._getQueryWhere('RIT_WHERE'),
          transactionWhere: this._getQueryWhere('TRANSACTION_WHERE'),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            let {
              deliveries: {ritTransactions, count},
            } = data;
            return (
              <View style={styles.bodyWrapper}>
                {this.props.renderSyncModal(() =>
                  refetchItems<DeliveryListResult, DeliveryListParams>(
                    fetchMore,
                    {
                      query: GET_DELIVERY_STATUS,
                      variables: {
                        ritWhere: this._getQueryWhere('RIT_WHERE'),
                        transactionWhere: this._getQueryWhere(
                          'TRANSACTION_WHERE',
                        ),
                        first: rowsPerPage,
                        skip: page * rowsPerPage,
                      },
                      dataKey,
                      rowsPerPage,
                      page,
                    },
                  ),
                )}
                <Table
                  data={ritTransactions || []}
                  dataCount={count || 0}
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    poNumber: {
                      alias: 'transaction.poNumber',
                      headerTitle: 'No. PO',
                    },
                    doNumber: {
                      alias: 'assignedOrder.doNumber',
                      headerTitle: 'No. DO',
                    },
                    invoiceNumber: {
                      alias: 'transaction.invoiceNumber',
                      headerTitle: 'Invoice',
                    },
                    driverCode: {
                      alias: 'rit.driver.driverCode',
                      headerTitle: 'Kode Driver',
                    },
                    driverName: {
                      alias: 'rit.driver.driverName',
                      headerTitle: 'Nama Driver',
                    },
                    totalOrder: {
                      headerTitle: 'Total Pesanan',
                      render: ({assignedOrder: {deliveryItems}}) => (
                        <View style={styles.customField}>
                          <Popover
                            popoverTrigger={
                              <Text
                                size="small"
                                weight="bold"
                                color={NAVY_BLUE}
                                style={{letterSpacing: 1.5}}
                              >
                                {deliveryItems
                                  .map((delivery) => {
                                    return (
                                      delivery.transactionItem.products
                                        .filter(
                                          (product) =>
                                            product.productData.uom ===
                                            delivery.deliveryType,
                                        )
                                        .reduce(
                                          (prev, curr) =>
                                            prev + curr.unitQuantity,
                                          0,
                                        ) * delivery.transactionItem.quantity
                                    );
                                  })
                                  .reduce((prev, curr) => prev + curr, 0)}
                              </Text>
                            }
                          >
                            <PopoverTableCard
                              data={deliveryItems}
                              structure={{
                                code: {
                                  headerTitle: `Informasi Pesanan (${deliveryItems.length})`,
                                  render: ({
                                    transactionItem: {products, quantity},
                                    deliveryType,
                                  }) => {
                                    return (
                                      <Fragment>
                                        {products
                                          .filter((item) => {
                                            return (
                                              item.productData.uom ===
                                              deliveryType
                                            );
                                          })
                                          .map((product) => {
                                            return (
                                              <View
                                                style={[
                                                  styles.dataRow,
                                                  {width: 300},
                                                ]}
                                              >
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 7,
                                                  }}
                                                >
                                                  {product.productData.title}{' '}
                                                </Text>
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 1,
                                                  }}
                                                >
                                                  -
                                                </Text>
                                                <Text
                                                  size="small"
                                                  color={BLACK}
                                                  style={{
                                                    letterSpacing: 1.5,
                                                    flex: 3,
                                                  }}
                                                >
                                                  {product.unitQuantity}
                                                  {' x '}
                                                  {quantity}{' '}
                                                </Text>
                                              </View>
                                            );
                                          })}
                                      </Fragment>
                                    );
                                  },
                                },
                              }}
                            />
                          </Popover>
                        </View>
                      ),
                    },
                    orderReceived: {
                      headerTitle: 'Pesanan Diterima',
                      render: ({
                        assignedOrder: {deliveryItems},
                        returnedTransactionItems,
                      }) => (
                        <View style={styles.customField}>
                          <Text>
                            {deliveryItems.reduce(
                              (acc, item) => acc + item.quantityDelivery,
                              0,
                            ) -
                              returnedTransactionItems.reduce(
                                (acc, item) => acc + item.quantity,
                                0,
                              )}
                          </Text>
                        </View>
                      ),
                    },
                    szID: {
                      alias: 'transaction.user.szID',
                      headerTitle: 'Kode Pelanggan',
                    },
                    storeName: {
                      alias: 'transaction.user.storeName',
                      headerTitle: 'Nama Toko',
                    },
                    contact: {
                      alias: 'transaction.user.storeTelephone',
                      headerTitle: 'Kontak',
                    },
                    createdAt: {
                      alias: 'transaction.createdAt',
                      processor: (date) => {
                        return new Date(date)
                          .toLocaleDateString('id')
                          .toString();
                      },
                      headerTitle: 'Tanggal Order',
                    },
                    deliveryDate: {
                      alias: 'transaction.deliveryDate',
                      processor: (date) => {
                        return new Date(date)
                          .toLocaleDateString('id')
                          .toString();
                      },
                      headerTitle: 'Tanggal Dikirim',
                    },
                    deliveredDate: {
                      alias: 'transaciton.deliveredDate',
                      processor: (date) => {
                        return new Date(date)
                          .toLocaleDateString('id')
                          .toString();
                      },
                      headerTitle: 'Tanggal Sampai',
                    },
                    pendingReason: {
                      alias: 'assignedOrder.pendingReason',
                      headerTitle: 'Alasan Tunda',
                    },
                    actions: {
                      render: ({
                        transaction,
                        returnedTransactionItems,
                        assignedOrder,
                      }) => (
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Icon
                            size="small"
                            name="description"
                            color={GRAY}
                            hoverColor={PRIMARY}
                            onPress={() => {
                              this.setState({
                                isInvoiceVisible: true,
                                actionData: {
                                  ...transaction,
                                  ...assignedOrder,
                                },
                                returnedData: {
                                  id: transaction.id,
                                  invoiceNumber: transaction.invoiceNumber,
                                  returnedTransactionItems,
                                },
                              });
                            }}
                          />
                        </View>
                      ),
                      noHeaderName: true,
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_DELIVERY_STATUS,
                      variables: {
                        ritWhere: this._getQueryWhere('RIT_WHERE'),
                        transactionWhere: this._getQueryWhere(
                          'TRANSACTION_WHERE',
                        ),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
                {this.state.actionData && this.state.returnedData ? (
                  <OrderDetailModal
                    order={this.state.actionData}
                    returnedItem={this.state.returnedData}
                    isVisible={this.state.isInvoiceVisible}
                    onClose={() => this.setState({isInvoiceVisible: false})}
                  />
                ) : null}
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  dataRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 15,
  },
  customField: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(DeliveredOrderList);
