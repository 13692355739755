import gql from 'graphql-tag';

import {DataValue} from 'react-apollo';

import {location} from '../relationalFragments';

export type Depot = {
  id: string;
  title: string;
  coverage: Array<{
    province: {id: string; name: string};
    city: {id: string; name: string};
    district: {id: string; name: string};
    subDistrict: {id: string; name: string};
    latitude: string;
    longitude: string;
    postalCode: string;
  }>;
};
export type DepotComplete = Depot & {
  cpName: string;
  cpNumber: string;
  active: boolean;
};
export type DepotList = {depots: Array<Depot>};
export type DepotListData = DataValue<DepotList, {}>;
export const GET_DEPOT_LIST = gql`
  query getDepotList($where: DepotWhereInput) {
    depots(where: $where) {
      id
      title
      coverage ${location}
    }
  }
`;
