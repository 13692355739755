import React, {Fragment} from 'react';
import {View, StyleSheet} from 'react-native';
import {
  Table,
  RowsPerPage,
  Query,
  ErrorScreen,
  DepotPicker,
  ResetFilterButton,
} from '../../components';
import {Button} from '../../core-ui';
import {graphql, compose} from 'react-apollo';
import {SearchState} from '../../graphql/localState';
import {
  GET_SEARCH_STATE,
  GET_DRIVERS,
  DriverListResult,
  DriverListParams,
  SearchStateProps,
} from '../../graphql/queries';
import {
  fetchMoreItems,
  refetchItems,
  asyncStorage,
  convertDepoToPostgresqlIDpro,
} from '../../helpers';
import {BLACK} from '../../constants/colors';

type State = {
  selectedDepo: Nullable<string>;
  resetPage: boolean;
  page: number;
  rowsPerPage: RowsPerPage;
  nameUserDepo: string;
  idPostresqlDepo: string;
};

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = SceneProps & SearchStateProps & {role: string};

export class DriverVehicleList extends React.Component<Props, State> {
  state: State = {
    selectedDepo: '',
    resetPage: false,
    page: 0,
    rowsPerPage: 10,
    nameUserDepo: '',
    idPostresqlDepo: '',
  };

  async componentDidMount() {
    let nameUserDepo = (await asyncStorage.getName()) || '';
    this.setState({nameUserDepo});

    try {
      this.setState({
        idPostresqlDepo: convertDepoToPostgresqlIDpro(nameUserDepo),
      });
    } catch (error) {
      this.setState({idPostresqlDepo: ''});
    }

    /*
      pastikan untuk di function helper convertDepoToPostgresqlID di ganti staging atau prodction sesuai dengan kebutuhan
    */
  }

  _getQueryWhere = () => {
    let {
      searchStateQuery: {searchState},
    } = this.props;

    let {selectedDepo, idPostresqlDepo} = this.state;

    // if (searchState) {
    //   let searchText = searchState.searchedString.toLowerCase();
    //   return {
    //     OR: [
    //       {driverCode_contains: searchText},
    //       {driverName_contains: searchText},
    //       {vehicle: {licensePlate_contains: searchText}},
    //     ],
    //   };
    // }
    // return {};

    return {
      ...(selectedDepo || idPostresqlDepo
        ? {depot: {id: selectedDepo || idPostresqlDepo}}
        : {}),
      OR: [
        {
          driverCode_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverNIK_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverName_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
      ],
      // driverName_contains: searchState
      //   ? searchState.searchedString.toLowerCase()
      //   : ''
    };
  };

  render() {
    return (
      <Fragment>
        {this._renderFilters()}
        {this._renderTable()}
      </Fragment>
    );
  }

  _renderTable = () => {
    let {rowsPerPage, page} = this.state;
    let {resetPage, setResetPage} = this.props;
    const dataKey = 'drivers';
    return (
      <Query<DriverListResult, DriverListParams>
        query={GET_DRIVERS}
        variables={{
          where: this._getQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            let dataTable = data.drivers.map((item) => {
              let {licensePlate, vehicleType} = item.vehicle;
              let {typeName} = vehicleType;
              return {
                ...item,
                licensePlate,
                typeName,
              };
            });
            return (
              <View style={styles.bodyWrapper}>
                {this.props.renderSyncModal(() =>
                  refetchItems<DriverListResult, DriverListParams>(fetchMore, {
                    query: GET_DRIVERS,
                    variables: {
                      where: this._getQueryWhere(),
                      first: rowsPerPage,
                      skip: page * rowsPerPage,
                    },
                    dataKey,
                    rowsPerPage,
                    page,
                  }),
                )}
                <Table
                  data={dataTable || []}
                  dataCount={data.count || 0}
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                  page={page}
                  isLoading={loading}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    driverCode: {headerTitle: 'Kode Driver'},
                    driverName: {headerTitle: 'Nama Driver'},
                    licensePlate: {
                      alias: 'vehicle.licensePlate',
                      headerTitle: 'Nomor Polisi',
                    },
                    brandName: {
                      alias: 'vehicle.brandName',
                      headerTitle: 'Jenis Kendaraan',
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_DRIVERS,
                      variables: {
                        where: this._getQueryWhere(),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
              </View>
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  };

  _renderFilters = () => {
    let {selectedDepo} = this.state;
    return (
      this.props.role === 'SUPER_ADMIN' && (
        <View style={styles.filters}>
          <Fragment>
            <View style={styles.filterRow}>
              <DepotPicker
                isFilter
                style={styles.filterMargin}
                selectedOption={selectedDepo}
                onChange={(selected) => {
                  this.setState({
                    selectedDepo: selected ? selected.value : selected,
                    resetPage: true,
                  });
                }}
                hidden={this.props.role !== 'SUPER_ADMIN'}
              />
            </View>
          </Fragment>
          <ResetFilterButton onPress={this._clearFilter} />
        </View>
      )
    );
  };

  _clearFilter = () => {
    this.setState({
      selectedDepo: null,
    });
  };
}

const filterStyle = StyleSheet.create({
  filterButton: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
});
const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    alignItems: 'flex-start',
    paddingTop: 10,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterMargin: {
    marginRight: 15,
  },
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(DriverVehicleList);
