import {PaymentType, PaymentStatus} from '../graphql/queries';

export function getPaymentTypeString(
  input: PaymentType,
  uppercase: boolean = true,
) {
  let output = '-';
  switch (input) {
    case 'TRANSFER':
      output = 'Transfer Bank';
      break;
    case 'CASH':
      output = 'Tunai';
      break;
  }
  return uppercase ? output.toUpperCase() : output;
}

export function getPaymentStatusString(
  input: PaymentStatus,
  uppercase: boolean = true,
) {
  let output = '-';
  switch (input) {
    case 'PAID':
      output = 'Lunas';
      break;
    case 'UNPAID':
      output = 'Belum Lunas';
      break;
  }
  return uppercase ? output.toUpperCase() : output;
}
