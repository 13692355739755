import gql from 'graphql-tag';
import {DataValue} from 'react-apollo';
import {SearchState} from '../localState';

export type SearchStateProps = {searchStateQuery: DataValue<SearchState, {}>};

export const GET_SEARCH_STATE = gql`
  query {
    searchState @client {
      searchedString
    }
  }
`;

export const UPDATE_SEARCH_STATE = gql`
  mutation updateSearch($searchedString: String) {
    updateSearch(searchedString: $searchedString) @client
  }
`;
