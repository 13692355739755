import gql from 'graphql-tag';

const location = `
  {
    id
    province {
      id
      name
    }
    city {
      id
      name
    }
    district {
      id
      name
    }
    subDistrict {
      id
      name
    }
    postalCode
  }
`;

const top = `
  top {
    id
    userId
    contractNumber
    contractStart
    details
    paymentPeriod
    isCredit
    creditLimit
    invoiceType
    active
  }
`;

const depot = `
  depot {
    id
    title
    description
    cpName
    cpNumber
    coverage ${location}
  }
`;

const user = `
  user {
    id
    szID
    email
    name
    storeName
    storeAddress
    storeTelephone
    phone
    phone2
    phone3
    whatsapp
    tvipCredit
    tvipPoint
    location ${location}
    ${depot}
    ${top}
    active
  }
`;

const productData = `
  productData {
    productID
    title
    nickname
    description
    stock
    photo
    dimension
    uom
    sku
    minimumStock
  }
`;

const products = `
  products {
    ${productData}
    unitQuantity
  }
`;

const bottleGuarantee = `
  bottleGuarantee {
    id
    qty
    pricePerBottle
    active
  }
`;

const transactionItems = `
  transactionItems {
    ${products}
    additionalProducts {
      name
      quantity
    }
    price
    priceCut
    quantity
   }
`;

const returnedTransactionItems = `
  returnedTransactionItems {
    id
    ${transactionItems.replace('transactionItems', 'transactionItem')}
    quantity
    returnReason
  }
`;

const returnedItems = `
  returnedItems {
    id
    invoiceNumber
    ${returnedTransactionItems}
  }
`;

const coupon = `
  coupon {
    id
    title
    description
    couponImage
    couponType
    couponRewardValue
    couponPrice
    couponLimit
    couponClaimCount
    couponValidDays
    startDate
    endDate
    createdAt
    active
  }
`;

export const canceledOrders = `
  canceledOrders {
    cancelReason
    deliveryItems {
      quantityDelivery
      transactionItem {
        id
        price
        priceCut
        ${products}
      }
    }
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment LocationData on Location {
    id
    province {
      id
      name
    }
    city {
      id
      name
    }
    district {
      id
      name
    }
    subDistrict {
      id
      name
    }
    postalCode
    latitude
    longitude
  }
`;

export const INVOICE_USER_FRAGMENT = gql`
  fragment InvoiceUserData on User {
    id
    email
    name
    storeName
    storeAddress
    storeTelephone
    phone
    phone2
    phone3
    whatsapp
    tvipCredit
    tvipPoint
    location {
      ...LocationData
    }
    active
  }
  ${LOCATION_FRAGMENT}
`;

export {
  bottleGuarantee,
  coupon,
  depot,
  location,
  returnedItems,
  returnedTransactionItems,
  top,
  transactionItems,
  user,
};
