import React, {Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Mutation, MutationFn, graphql, compose} from 'react-apollo';
import {RouteComponentProps} from 'react-router-dom';

import {Form} from '../../components';
import {LandingBackground} from './';
import {Button, Text, TextField} from '../../core-ui';
import {graphqlErrorRemover} from '../../helpers';
import {
  USER_LOGIN,
  SAVE_USER_LOGIN,
  LoginMutationResults,
  LoginMutationVariables,
} from '../../graphql/queries';
import {UserContent} from '../../graphql/localState';
import {AuthenticatedContext} from '../../routes/MainRoute';

import {PRIMARY, LIGHT_RED, RED, BAHAMA_BLUE} from '../../constants/colors';

type State = {
  email: string;
  password: string;
};

type LoginSuccessProps = {
  loginSuccess: MutationFn<null, UserContent>;
};

type Props = RouteComponentProps & LoginSuccessProps;

class LoginPage extends Component<Props, State> {
  state: State = {
    email: '',
    password: '',
  };

  render() {
    return <LandingBackground>{this._renderLogin()}</LandingBackground>;
  }

  _renderLogin = () => {
    let {email, password} = this.state;
    let {history, loginSuccess} = this.props;
    return (
      <AuthenticatedContext.Consumer>
        {(refetch) => (
          <Mutation<LoginMutationResults, LoginMutationVariables>
            mutation={USER_LOGIN}
            update={async (_, {data}) => {
              if (data) {
                let {token, provider} = data.cmsLogin;
                let {id, role, name, email: providerEmail} = provider;
                await loginSuccess({
                  variables: {
                    userID: id,
                    email: providerEmail,
                    name,
                    token,
                    role,
                  },
                });
                refetch();
              }
            }}
          >
            {(login, {loading, error}) => {
              return (
                <Form onSubmit={() => this._submitLoginForm(login)}>
                  <Text size="xlarge" style={styles.header}>
                    Selamat Datang di TVIP
                  </Text>
                  {error &&
                    this._renderErrorBox(graphqlErrorRemover(error.message))}
                  <TextField
                    stretch
                    label="Email"
                    value={email}
                    placeholder="Email address"
                    onChangeText={this._onEmailTextChange}
                    style={{
                      container: {paddingBottom: 20, marginTop: 20},
                      textField: {
                        color: BAHAMA_BLUE,
                      },
                    }}
                  />
                  <TextField
                    stretch
                    secureTextEntry
                    label="Password"
                    value={password}
                    placeholder="Password"
                    onChangeText={this._onPasswordTextChange}
                    style={{
                      container: {paddingBottom: 20},
                      textField: {
                        color: BAHAMA_BLUE,
                      },
                    }}
                  />
                  <View style={styles.forgotPasswordBox}>
                    <TouchableOpacity
                      onPress={() => history.push('/forgot-password')}
                    >
                      <Text
                        color={PRIMARY}
                        weight="light"
                        size="small"
                        style={styles.forgotPassword}
                      >
                        Lupa kata sandi?
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View>
                    <Button
                      color="primary"
                      stretch
                      text="Masuk"
                      style={styles.loginButton}
                      onPress={() => this._submitLoginForm(login)}
                      isLoading={loading}
                    />
                  </View>
                </Form>
              );
            }}
          </Mutation>
        )}
      </AuthenticatedContext.Consumer>
    );
  };

  _renderErrorBox = (message: string) => {
    return (
      <View style={styles.errorBox}>
        <Text size="xsmall">{message}</Text>
      </View>
    );
  };

  _submitLoginForm = (login: MutationFn<any, LoginMutationVariables>) => {
    let {email, password} = this.state;
    login({
      variables: {
        email,
        password,
      },
    });
  };

  _onEmailTextChange = (text: string) => {
    this.setState({
      email: text,
    });
  };

  _onPasswordTextChange = (text: string) => {
    this.setState({
      password: text,
    });
  };
}

const styles = StyleSheet.create({
  errorBox: {
    padding: 10,
    borderWidth: 1,
    backgroundColor: LIGHT_RED,
    borderColor: RED,
    borderRadius: 3,
  },
  header: {
    paddingBottom: 20,
  },
  forgotPassword: {
    paddingBottom: 40,
  },
  loginButton: {
    padding: 0,
    margin: 0,
  },
  forgotPasswordBox: {
    alignItems: 'flex-end',
  },
});

export default compose(
  graphql<{}, LoginSuccessProps, UserContent, LoginSuccessProps>(
    SAVE_USER_LOGIN,
    {name: 'loginSuccess'},
  ),
)(LoginPage);
