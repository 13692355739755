import gql from 'graphql-tag';

export const UPDATE_DRIVER_VEHICLE_TYPE = gql`
  mutation driverSync {
    driverSync {
      updatedVehicleTypeCount
      updatedVehicleCount
      updatedDriverCount
      message
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation orderSync {
    orderSync {
      count
    }
  }
`;
