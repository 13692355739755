import gql from 'graphql-tag';

export type VehicleType = {
  id: string;
  typeID: string;
  typeName: string;
  capacity: number;
  info: string;
  active: boolean | true;
};

export type VehicleTypeListResult = {
  vehicleTypes: Array<VehicleType>;
  count: number;
};

export type VehicleTypeListParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

const vehicleTypeFragment = gql`
  fragment VehicleTypeFragment on VehicleType {
    id
    typeID
    typeName
    capacity
    info
    active
  }
`;

export const GET_VEHICLE_TYPES = gql`
  query getVehicleTypes(
    $where: VehicleTypeWhereInput
    $skip: Int
    $first: Int
  ) {
    vehicleTypes(where: $where, skip: $skip, first: $first) {
      ...VehicleTypeFragment
      __typename
    }
    count: dataCount(node: VEHICLE_TYPE, vehicleTypeWhere: $where)
  }
  ${vehicleTypeFragment}
`;
