import gql from 'graphql-tag';
import {DataValue} from 'react-apollo';

export type LoginMutationResults = {
  cmsLogin: {
    token: string;
    provider: {
      id: string;
      role: string;
      name: string;
      email: string;
    };
  };
};

export type LoginMutationVariables = {
  email: string;
  password: string;
};

export const SAVE_USER_LOGIN = gql`
  mutation loginSuccess(
    $userID: String!
    $token: String!
    $role: String!
    $name: String!
    $email: String!
  ) {
    loginSuccess(
      userID: $userID
      token: $token
      role: $role
      name: $name
      email: $email
    ) @client
  }
`;

export const USER_LOGIN = gql`
  mutation CmsLogin($email: String!, $password: String!) {
    cmsLogin(email: $email, password: $password, checkRole: true) {
      token
      provider {
        id
        email
        name
        role
      }
    }
  }
`;

export const USER_LOGOUT = gql`
  mutation {
    logout @client
  }
`;

export type IsCmsAuthenticatedData = DataValue<
  {
    isCmsAuthenticated: boolean;
  },
  {}
>;

export const IS_CMS_AUTHENTICATED = gql`
  query {
    isCmsAuthenticated
  }
`;
