import React, {Component} from 'react';

import {View, StyleSheet} from 'react-native';
import {
  compose,
  graphql,
  MutationFunc,
  Mutation,
  OperationVariables,
} from 'react-apollo';
import {withRouter, RouteComponentProps} from 'react-router-dom';

import {SearchField} from '../../components';
import {Text, Tabs, Tab, Modal, Button} from '../../core-ui';
import {
  UPDATE_SEARCH_STATE,
  GET_SEARCH_STATE,
  DriverListResult,
  SearchStateProps,
} from '../../graphql/queries';
import {SearchState} from '../../graphql/localState';
import withToast, {ToastContextProps} from '../../helpers/withToast';
import {VehicleTypeList, VehicleMasterList} from '.';
import {DARK_GRAY, WHITE} from '../../constants/colors';
import {UPDATE_DRIVER_VEHICLE_TYPE} from '../../graphql/mutations';

type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type Props = RouteComponentProps &
  SearchStateProps &
  UpdateSearchData &
  ToastContextProps;
type State = {
  selectedTab: number;
  isUpdateVisible: boolean;
  resetPage: boolean;
};

const TAB_HASH_URI = ['#tipe-kendaraan', '#master-kendaraan'];

export class VehicleScene extends Component<Props, State> {
  state: State = {
    selectedTab: 0,
    isUpdateVisible: false,
    resetPage: false,
  };

  componentWillMount() {
    let {location} = this.props;
    if (location.hash === TAB_HASH_URI[1]) {
      this.setState({selectedTab: 1});
    }
  }

  componentWillUnmount() {
    this._clearSearch();
  }

  componentDidUpdate(prevProps: Props) {
    let {
      searchStateQuery: {searchState: prevSearch},
    } = prevProps;
    let {
      searchStateQuery: {searchState},
    } = this.props;
    if (prevSearch && searchState) {
      if (prevSearch.searchedString !== searchState.searchedString) {
        this.setState({resetPage: true});
      }
    }
  }

  _clearSearch = () => {
    let {updateSearch} = this.props;
    updateSearch({variables: {searchedString: ''}});
  };

  render() {
    return (
      <View style={styles.root}>
        {this._renderHeader()}
        {this._renderTabs()}
      </View>
    );
  }

  _setResetPage = (reset: boolean) => {
    this.setState({resetPage: reset});
  };

  _closeAdd = () => this.setState({isUpdateVisible: false});

  _onActivateError = () => {
    let {openToast} = this.props;
    openToast && openToast('fail', 'Data gagal diperbarui.');
  };

  _renderSyncModal = (refetchFn: () => void) => {
    let {isUpdateVisible} = this.state;
    return (
      <Mutation<DriverListResult, OperationVariables>
        mutation={UPDATE_DRIVER_VEHICLE_TYPE}
        onCompleted={() => {
          let {openToast} = this.props;
          openToast && openToast('success', 'Data berhasil diperbarui.');
          refetchFn();
          this.setState({isUpdateVisible: false});
        }}
        onError={this._onActivateError}
      >
        {(updateDatabase, {loading}) => {
          return (
            <Modal
              isVisible={isUpdateVisible}
              title="Perbarui Data Driver dan Kendaraan"
              isLoading={loading}
              description={
                <Text size="small" color={DARK_GRAY}>
                  Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                  yakin ingin meng-update/memperbarui data?{' '}
                </Text>
              }
              children={
                <View style={styles.modalView}>
                  <Button
                    style={{marginRight: 15}}
                    inverted
                    color="secondary"
                    text="Batal"
                    onPress={this._closeAdd}
                    loadingColor={WHITE}
                  />
                  <Button
                    color="primary"
                    text="Ya"
                    onPress={() => updateDatabase()}
                    loadingColor={WHITE}
                    isLoading={loading}
                  />
                </View>
              }
              onClose={this._closeAdd}
            />
          );
        }}
      </Mutation>
    );
  };

  _renderHeader() {
    let {
      searchStateQuery: {searchState},
      updateSearch,
    } = this.props;
    return (
      <View style={styles.header}>
        <Text size="xlarge">Kapasitas Kendaraan</Text>
        <View style={styles.header}>
          <SearchField
            value={searchState ? searchState.searchedString : ''}
            onChangeText={(text) =>
              updateSearch({variables: {searchedString: text}})
            }
          />
          <Button onPress={() => this.setState({isUpdateVisible: true})}>
            Update Data
          </Button>
        </View>
      </View>
    );
  }

  _renderTabs() {
    let {selectedTab, resetPage} = this.state;
    return (
      <Tabs
        selectedIndex={selectedTab}
        onChange={(_e, index) => {
          let {history, location} = this.props;
          this._clearSearch();
          this.setState({selectedTab: index});
          history.push(`${location.pathname}${TAB_HASH_URI[index]}`);
        }}
      >
        <Tab label="Tipe Kendaraan">
          <VehicleTypeList
            renderSyncModal={this._renderSyncModal}
            resetPage={resetPage}
            setResetPage={this._setResetPage}
          />
        </Tab>
        <Tab label="Master Kendaraan">
          <VehicleMasterList
            renderSyncModal={this._renderSyncModal}
            resetPage={resetPage}
            setResetPage={this._setResetPage}
          />
        </Tab>
      </Tabs>
    );
  }
}

const styles = StyleSheet.create({
  root: {flex: 1, paddingVertical: 40, paddingHorizontal: 80},
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  tabContent: {paddingTop: 20},
  modalView: {flexDirection: 'row', justifyContent: 'flex-end'},
});

export default compose(
  withRouter,
  withToast,
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, UpdateSearchVariables, UpdateSearchData>(
    UPDATE_SEARCH_STATE,
    {name: 'updateSearch'},
  ),
)(VehicleScene);
