import React, {ReactNode, Component, ReactElement} from 'react';
import {StyleProp, ViewStyle, View, StyleSheet} from 'react-native';
import classNames from 'classnames';
import {
  withStyles,
  WithStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import {Text} from '../core-ui';
import NoDataPlaceholder from './NoDataPlaceholder';
import {BACKDROP_TRANSPARENT, WHITE} from '../constants/colors';

type StructureElement<T = ObjectKey> = {
  render?: (data: T, index: number) => ReactNode;
  headerTitle?: string;
  noHeaderName?: boolean;
  headerCenter?: boolean;
};

export type TableStructure<T = ObjectKey> = {
  [key: string]: StructureElement<T>;
};

type OwnProps<T = ObjectKey> = {
  data: Array<T>;
  structure: TableStructure<T>;
  noDataPlaceholder?: string;
};

type Props<T = ObjectKey> = OwnProps<T> & WithStyles;

class PopoverTableCard<T = ObjectKey> extends Component<Props<T>, {}> {
  render() {
    let {noDataPlaceholder = 'Tidak ada data', data} = this.props;

    if ((data && data.length < 1) || !data) {
      return <NoDataPlaceholder text={noDataPlaceholder} />;
    }

    return (
      <Paper square={true} elevation={0} style={styles.root}>
        <Paper elevation={0} style={styles.bodyRoot}>
          <Table>
            {this._renderTableHead()}
            {this._renderTableBody()}
          </Table>
        </Paper>
      </Paper>
    );
  }

  _renderTableHead = () => {
    let {structure, classes} = this.props;
    let cells = Object.keys(structure);

    let headerContent = cells.map((headerName, index) => {
      let {noHeaderName, headerTitle, headerCenter} = structure[headerName];
      let headerStyle: StyleProp<ViewStyle> = headerCenter
        ? {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }
        : {
            flexDirection: 'row',
            alignItems: 'center',
          };
      let headerChild = (
        <View style={headerStyle}>
          <Text size="xsmall" weight="bold" style={nativeStyles.cellText}>
            {headerTitle ? headerTitle.toUpperCase() : headerName.toUpperCase()}
          </Text>
        </View>
      );
      return (
        <TableCell key={index}>{noHeaderName ? null : headerChild}</TableCell>
      );
    });
    return (
      <TableHead>
        <TableRow className={classes.headerRow}>{headerContent}</TableRow>
      </TableHead>
    );
  };

  _renderTableRow = (currentRow: T, parentIndex: number) => {
    let {structure, classes} = this.props;
    let cells = Object.keys(structure);

    return (
      <TableRow className={classes.row} key={parentIndex}>
        {cells.map((headerName, index) => {
          let {render} = structure[headerName];
          let dataRow = currentRow[headerName as keyof T];
          return (
            <TableCell
              className={classNames(
                classes.body,
                classes.cellRoot,
                classes.rowCell,
              )}
              key={index}
            >
              {render ? (
                render(currentRow, parentIndex)
              ) : (
                <Text size="small" style={nativeStyles.cellText}>
                  {dataRow != null ? String(dataRow).toUpperCase() : '-'}
                </Text>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  _renderTableBody = () => {
    let {data} = this.props;
    return (
      <TableBody style={styles.body}>
        {data.map((currentRow, parentIndex) => {
          return this._renderTableRow(currentRow, parentIndex);
        })}
      </TableBody>
    );
  };
}

const nativeStyles = StyleSheet.create({
  cellText: {letterSpacing: 1.5},
});

const styles = createStyles({
  root: {backgroundColor: 'transparent'},
  bodyRoot: {
    overflowX: 'auto',
    maxHeight: 300,
    backgroundColor: 'transparent',
    paddingRight: 1, // NOTE: this is necessary to counter-balance the right border of last-child
  },
  body: {
    backgroundColor: WHITE,
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  row: {
    height: 40,
    backgroundColor: WHITE,
    borderStyle: 'none',
  },
  rowCell: {
    borderStyle: 'none',
  },
  headerRow: {
    height: 40,
    color: BACKDROP_TRANSPARENT,
  },
  table: {
    minWidth: 700,
  },
});

export default withStyles(styles)(PopoverTableCard) as <T = ObjectKey>(
  props: OwnProps<T>,
) => ReactElement;
