import React, {Component, Fragment, ReactNode} from 'react';
import {
  View,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native';
import {Popover as BasePopover} from '@material-ui/core';
import {NAVY_BLUE} from '../constants/colors';

type Props = TouchableOpacityProps & {
  popoverTrigger: ReactNode;
};

type State = {
  anchorEl: null | number;
};

export default class Popover extends Component<Props, State> {
  state = {
    anchorEl: null,
  };

  render() {
    let {anchorEl} = this.state;
    let open = Boolean(anchorEl);
    let {children, popoverTrigger, ...otherProps} = this.props;

    return (
      <Fragment>
        <TouchableOpacity onPress={this._handleClick} {...otherProps}>
          {popoverTrigger}
        </TouchableOpacity>
        <BasePopover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this._handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <View style={styles.popoverContainer}>{children}</View>
        </BasePopover>
      </Fragment>
    );
  }

  _handleClick = (event: GestureResponderEvent) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  _handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
}

const styles = StyleSheet.create({
  popoverContainer: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 20,
    marginRight: 0.5,
    borderColor: NAVY_BLUE,
  },
});
